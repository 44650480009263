//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : Profile.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      Function features short description
//* 20240718     Jecyline     add chinese translation zh.json
//
//***************************************************************************/
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require('../src/assets/i18n/en.json')
      },
      fr: {
        translation: require('../src/assets/i18n/fr.json')
      },
      es: { // Corrected language code for Spanish
        translation: require('../src/assets/i18n/es.json')
      },
      de: {
        translation: require('../src/assets/i18n/de.json')
      },
      zh: { //Chinese
        translation: require('../src/assets/i18n/zh.json')
      }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

