//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : dashboard.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      Function features short description
//* 20240718     Jecyline     language selection module
//* 20240726     Jecyline     add create new chat function
//* 20240801     Glynnis      hide api/dash.json
//* 20240805     Jecyline     add feedback form
//* 20240806     Jecyline     amend chatSlice directory
//* 20240807     Jecyline     set feedbackModalShown to true before call api
//* 20240808     Jecyline     Add sweet alert to feedback submission
//* 20240814     Jecyline     Amend dashboard content
//* 20240830     Jecyline     set chat memory and chat details for selected chat room
//* 20240905     Jecyline     move create chat function to chatSagas
//* 20240903     Glynnis      revamp UI
//
//***************************************************************************/
import React, { useEffect, useState } from "react";
import ChatsOverview from "./chats-overview";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useTranslation } from 'react-i18next';
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'; // Import the custom hook
import { api } from '../../../../services/api';
import { useDispatch } from 'react-redux';
import NewChatModal from '../../../../components/modelpopup/NewChatModal'; // Import the new modal component
import { useNavigate } from "react-router-dom";
import FeedbackModal from '../../../../components/modelpopup/FeedbackModal';
import Swal from 'sweetalert2';
import { GrDiamond } from "react-icons/gr";
import { FaArrowRightToBracket, FaUser } from "react-icons/fa6";
import { 
  createChatAction
} from '../../../../store/actions/chatActions';

const AdminDashboard = () => {
  useAxiosInterceptors(); 
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showNewChatModal, setShowNewChatModal] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    const feedbackModalShown = localStorage.getItem('feedbackModalShown');
    if (feedbackModalShown === 'false') {
      localStorage.setItem('feedbackModalShown', 'true');
      checkChat();
    }

    const fetchData = async () => {
      try {
        const response = await api.get('/dashboard');
        console.log('response',response);
        if (response.status === 200) {
          if(response.data.chart_data){
            const translatedChartData = response.data.chart_data.map(item => ({
              title: t(item.title),
              amount: item.amount
            }));
  
            setChartData(translatedChartData);
          }
          
          setCardData(response.data.card_data || {});
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  },[t]);

  const checkChat = async () => {
    try {
      const response = await api.get('/get-chat');

      if (response.status === 200) {
        const { chats } = response.data;

        if (chats && chats.length > 0) {
          setShowFeedbackModal(true);
        }
      } else {
        console.error('Failed to get data. Response status:', response.status);
      }
    } catch (error) {
      console.error('Failed to get data:', error);
    }
  };

  const handleCloseFeedbackModal = () => {
    setShowFeedbackModal(false);
  };

  const handleCreateFeedback = async (feedback) => {
    try {
      const { ratings, didHelp, improvementSuggestion } = feedback;

      const formData = new FormData();
      formData.append('ratings', ratings);
      formData.append('did_help', didHelp);
      formData.append('improvement_suggestion', improvementSuggestion);

      // Add new feedback
      const response = await api.post('/feedback', formData);

      if (response.status === 200) {
        handleCloseFeedbackModal();
        Swal.fire({
          icon: 'success',
          title: t('Thank You!'),
          text: t('We appreciate your feedback. It helps us improve our service.'),
          confirmButtonText: t('OK'),
        });
    
      } else {
        console.error('Failed to submit feedback. Response status:', response.status);
        Swal.fire({
          icon: 'error',
          title: t('Oops!'),
          text: t("Something went wrong. We couldn't submit your feedback. Please try again later."),
          confirmButtonText: t('OK'),
        });
      }

    } catch (error) {
      console.error('Failed to submit feedback:', error);
    }
  };

  const handleOpenNewChatModal = () => {
    setShowNewChatModal(true);
  };

  const handleCloseNewChatModal = () => {
    setShowNewChatModal(false);
  };

  const handleCreateChat = async (chatName) => {
    /////////////////////////////////////
    // chatAction: createChatAction
    dispatch(createChatAction({ 
      chat_name: chatName
    }));

    // Close the modal after successful chat creation
    handleCloseNewChatModal();
    navigate('/chat');

  };

  return (
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs 
            maintitle={`${t('welcome')} ${cardData.name}!`} 
            title={t('Dashboard')} 
            handleOpenNewChatModal={handleOpenNewChatModal}
          />
          <div className="row">
            {/* Last Login */}
            <div className="col-12 col-sm-6 col-xl-4 d-flex">
              <div className="card profile-box flex-fill dashboard-card-wrapper">
                <div className="card-body dashboard-card">
                  <div className="row dashboard-wrapper">
                    {/* <div className="col-12 col-sm-6 dashboard-icon-container">
                      <FaArrowRightToBracket className="icon-size" />
                    </div> */}
                    <div className="col-12 col-sm-12 dashboard-text-container">
                      <div className="dashboard-text dashboard-title">
                        {t('Last Login')}
                      </div>
                      <div className="dashboard-text dashboard-description-time">
                        {/* 4 July 2024 10:00AM */}
                        {cardData.last_login}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Last Login end */}

            {/* Balance Credit */}
            <div className="col-12 col-sm-6 col-xl-4 d-flex">
              <div className="card profile-box flex-fill dashboard-card-wrapper">
                <div className="card-body dashboard-card">
                  <div className="row dashboard-wrapper">
                    {/* <div className="col-12 col-sm-6 dashboard-icon-container">
                      <GrDiamond className="icon-size" />
                    </div> */}
                    <div className="col-12 col-sm-12 dashboard-text-container">
                      <div className="dashboard-text dashboard-title">
                        {t('Balance Credit')}
                      </div>
                      <div className="dashboard-text dashboard-description-number">
                        {/* 3 */}
                        {cardData.balance_credit}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Balance Credit end */}

            {/* Successful Affiliate */}
            <div className="col-12 col-sm-6 col-xl-4 d-flex">
              <div className="card profile-box flex-fill dashboard-card-wrapper">
                <div className="card-body dashboard-card">
                  <div className="row dashboard-wrapper">
                    {/* <div className="col-12 col-sm-6 dashboard-icon-container">
                      <FaUser className="icon-size" />
                    </div> */}
                    <div className="col-12 col-sm-12 dashboard-text-container">
                      <div className="dashboard-text dashboard-title">
                        {t('Successful Affiliate')}
                      </div>
                      <div className="dashboard-text dashboard-description-number">
                        {/* 5 */}
                        {cardData.successful_affiliate}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Successful Affiliate end */}
          </div>
          <div className="row">
            {/* Chats Overview */}
            <div className="col-12">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    {t('Chats Overview')}
                  </h3> 
                  <div className="chat-overview-container">
                    <ChatsOverview 
                      chartData={chartData}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Chats Overview end */}
          </div>
          
        </div>
      </div>
      <NewChatModal 
        show={showNewChatModal} 
        onClose={handleCloseNewChatModal} 
        onCreate={handleCreateChat} 
      />
      <FeedbackModal 
        show={showFeedbackModal} 
        onClose={handleCloseFeedbackModal} 
        onCreate={handleCreateFeedback} 
      />
    </div>
  );
};

export default AdminDashboard;
