//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : GoogleSignUpButton.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240705     Jecyline     create GoogleSignUpButton.jsx
//* 20240717     Jecyline     amend handleSignupSuccess(), get REACT_APP_GOOGLE_CLIENT_ID from env, change plugin to react-oauth/google
//
//***************************************************************************/
import React from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { googleicon } from "../Routes/ImagePath";
// import { GoogleLogin } from 'react-google-login';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { api_url } from "../base_urls";

const GoogleSignUpButton = () => {
  const navigate = useNavigate();

  const handleSignupSuccess = async (response) => {
    console.log('Google signup response:', response);

    fetch(api_url + '/auth/google', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${response.credential}` // Include the token in the Authorization header
      },
      body: JSON.stringify({
        token: response.credential, // Send the token as part of the request body
        action: 'register', // Or 'login' based on your requirements
      }),
    })
    .then(res => res.json())
    .then(data => {
      // console.log(data);
      if(data.error){
        console.log('Error during backend request:', data);
      } else {
        console.log('Google login successful:', data);
        navigate('/otp', { state: { email: data.email } });
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  
  };

  const handleSignupFailure = (error) => {
    console.error('Sign Up Failed:', error);
  };

  
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <GoogleLogin
        onSuccess={handleSignupSuccess}
        onFailure={handleSignupFailure}
        style={{ display: 'none' }} // Hide the default button
        >
        {({ onClick }) => (
          <button className="account-icon-button" onClick={onClick}>
            <img src={googleicon} alt="Google" className="account-img-icon"/>
            Sign up with Google
          </button>
        )}
      </GoogleLogin>
    </GoogleOAuthProvider>
  );
  
  };
  

export default GoogleSignUpButton;


