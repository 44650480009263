//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : rootReducer.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (29 Aug 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240829     Jecyline      create rootReducer.jsx for chat reducer
//
//***************************************************************************/
import { combineReducers } from 'redux';

import userReducer from "./slices/userSlice"; // Make sure this import is correct
import chatReducer from './slices/chatSlice'; // Import the chat reducer
import fileManagerReducer from './slices/fileManagerSlice'; // Import the chat reducer

const rootReducer = combineReducers({
  user: userReducer,
  chat: chatReducer, // Add the chat reducer
  file_manager: fileManagerReducer, // Add the chat reducer
});

export default rootReducer;
