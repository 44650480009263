//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : chatSelectors.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (29 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240729     Jecyline      create chatSelectors.jsx for chat reducer
//* 20240813     Jecyline      add selectSelection, selectChatSelectionForCurrentChat
//* 20240829     Jecyline      add more selector
//* 20240905     Jecyline      add selectHasMore, selectIsLoadingChatDetails, selectIsLoadingFirstPage, selectChatStage
//* 20240911     Jecyline      fix bug for selectChatSelectionForCurrentChat
//
//***************************************************************************/
import { createSelector } from 'reselect';

// Selector to get currentChatData
export const selectCurrentChatData = (state) => state.chat.currentChatData;
export const selectChatSelection = (state) => state.chat.chatSelection;
export const selectChatLoader = (state) => state.chat.chatLoader;
export const selectChatMemory = (state) => state.chat.chatMemory;
export const selectCurrentPage = (state) => state.chat.currentPage;
export const selectHasMore = (state) => state.chat.hasMore;
export const selectIsLoadingChatDetails = (state) => state.chat.isLoadingChatDetails;
export const selectIsLoadingFirstPage = (state) => state.chat.isLoadingFirstPage;
export const selectChatStage = (state) => state.chat.chatStage;

// Memoized selector to get chatLoader based on currentChatData
export const selectChatLoaderForCurrentChat = createSelector(
  [selectCurrentChatData, selectChatLoader],
  (currentChatData, chatLoader) => {
    if (!currentChatData || !currentChatData.id) return { isLoading: false, message: '' };
    return chatLoader[currentChatData.id] || { isLoading: false, message: '' };
  }
);

// Memoized selector to get chatSelection based on currentChatData
export const selectChatSelectionForCurrentChat = createSelector(
  [selectCurrentChatData, selectChatSelection],
  (currentChatData, chatSelection) => {
    if (!currentChatData || !currentChatData.id) return { isDisplay: false, animationClass: 'hidden' };
    return chatSelection[currentChatData.id] || { isDisplay: false, animationClass: 'hidden' };
  }
);

// Memoized selector to get chatMemory based on currentChatData
export const selectChatMemoryForCurrentChat = createSelector(
  [selectCurrentChatData, selectChatMemory],
  (currentChatData, chatMemory) => {
    if (!currentChatData || !currentChatData.id) return [];
    return chatMemory[currentChatData.id] || [];
  }
);
