//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : bugsnag.js
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (23 August 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240823     Jecyline     create bugsnag.jsx
//***************************************************************************/
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

Bugsnag.start({
  apiKey: `${process.env.REACT_APP_BUGSNAG_API_KEY}`,
  plugins: [new BugsnagPluginReact(React)]
});

export default Bugsnag;
