//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : Login.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      create Login.jsx
//* 20240705     Jecyline     import FacebookLoginButton & GoogleLoginButton, handleSubmit() - do status checking
//* 20240712     Jecyline     simplify redundant codes, add refresh-token to local storage, navigate to first time login page for user with status = 2
//* 20240718     Jecyline     language selection module
//* 20240805     Jecyline     set feedbackModalShown to localStorage
//* 20240806     Jecyline     amend way to set refresh_token to local storage
//* 20240902     Glynnis      revamp UI
//
//***************************************************************************/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import axios from 'axios';
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { api_url } from "../../../base_urls";

import FacebookLoginButton from "../../../components/FacebookLoginButton";
import GoogleLoginButton from "../../../components/GoogleLoginButton";
// import { useDispatch } from "react-redux";
// import { login } from "../../../user";
// import { resetFunctionwithlogin } from "../../../components/ResetFunction";
// import { login } from "../../../user";
import LanguageSelector from '../../layout/LanguageSelector';
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be at most 20 characters")
    .required("Password is required"),
});

const Login = () => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(api_url + '/login', { email, password });

      const { access_token, token_type, expires_in, user } = response.data;

      if (user.status === 1) { //pending verification user

        navigate('/otp', { state: { email: user.email } });

        try {
          const response = await fetch(api_url + '/resend-otp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
          });
  
          if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.error || 'Failed to resend otp');
          } else {
            const responseData = await response.json();
            // console.log(responseData);
  
            setMessage(responseData.message);
            setTimeout(() => setMessage(''), 10000); // Clear message after 5 seconds
          }
  
        } catch (error) {
          console.error('Error:', error.message);
        }
      } else {
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('token_type', token_type);
        localStorage.setItem('expires_in', expires_in);
        localStorage.setItem('refresh_token', user.refresh_token);
        localStorage.setItem('feedbackModalShown', 'false');

        if (user.status === 2) { // first time login user
          navigate('/first-time-login');
        } else if (user.status === 3) { // active user
          navigate('/dashboard');
        }
      }
    }  
    catch (error) {
      console.error('Error:', error);

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.error('Server Error:', error.response.data);

        setMessage(error.response.data.error);
        setTimeout(() => setMessage(''), 10000);

      } else if (error.request) {
        // The request was made but no response was received
        // console.error('Network Error:', error.request);

        setMessage('Network error - please try again later');
        setTimeout(() => setMessage(''), 10000);

      } else {
        // Something else happened in making the request
        console.error('Error:', error.message);

        setMessage('Something went wrong - please try again later');
        setTimeout(() => setMessage(''), 10000);

      }
    }
  };

  const [eye, seteye] = useState(true);

  const onEyeClick = () => {
    seteye(!eye);
  };

  return (
    <div>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            
            <div className="container">
              {/* Account Logo */}
              <div className="account-logo">
                <Link to="/admin-dashboard">
                  <img src={Applogo} alt="Omninexus" />
                </Link>
              </div>
              {/* /Account Logo */}
              <div className="account-box">
                <div className="account-wrapper">
                  <LanguageSelector />
                  
                  {/* Message Display */}
                  {message && (
                    <div className={`alert ${message.includes('success') ? 'alert-success' : 'alert-danger'}`}>
                      {t(`errors.${message}`)}
                    </div>
                  )}
                  {/* /Message Display */}
                  {/* Account Form */}
                  <div>
                    <form onSubmit={handleSubmit}>
                      <div className="input-block mb-4">
                        <label className="col-form-label">{t('Email')}</label>
                        <Controller
                          type="text"
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${
                                error?.email ? "error-input" : ""
                              }`}
                              type="text"
                              value={email} 
                              onChange={(e) => setEmail(e.target.value)}
                              autoComplete="true"
                              required
                            />
                          )}
                        />
                      </div>
                      <div className="input-block mb-4">
                        <div className="row">
                          <div className="col">
                            <label className="col-form-label">{t('Password')}</label>
                          </div>
                          
                        </div>
                        <div style={{ position: "relative" }}>
                          <Controller
                            type="password"
                            name="password"
                            control={control}
                            render={({ field }) => (
                              <input
                                // className="form-control"
                                className={`form-control ${
                                  error?.password ? "error-input" : ""
                                }`}
                                type={eye ? "password" : "text"}
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                            )}
                          />
                          <div className="col-auto">
                            <Link className="text-muted" to="/forgot-password">
                              {t('Forgot Password')}?
                            </Link>
                          </div>
                          <span
                            style={{
                              position: "absolute",
                              right: "5%",
                              top: "30%",
                            }}
                            onClick={onEyeClick}
                            className={`fa-solid ${
                              eye ? "fa-eye-slash" : "fa-eye"
                            } `}
                          />
                        </div>
                        <span className="text-danger">
                          {" "}
                          {error && <p style={{ color: 'red' }}>{error}</p>}
                        </span>
                      </div>
                      <div className="input-block text-center">
                        <button
                          className="btn btn-primary account-btn"
                          type="submit"
                        >
                          {t('Login')}
                        </button>
                      </div>
                      
                    </form>
                    <div className="account-footer">
                      <p>
                        {t("Don't have an account yet")}?{" "}
                        <Link to="/register"> {t('Register')}</Link>
                      </p>
                    </div>
                    <div className="input-block" style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                      <div className="horizontal-line"/>
                      <span style={{ color: '#9A9797', padding: '0 5px' }}>{t('OR')}</span>
                      <div className="horizontal-line"/>
                    </div>
                    {/* <div className="input-block text-center">
                      <button
                        className="btn account-icon-button"
                        type="button"
                      >
                        <i className="fab fa-apple account-icon"></i>
                        <div className="account-text">Sign in with Apple</div>
                      </button>
                    </div> */}
                    <div className="input-block text-center">
                      <FacebookLoginButton />
                    </div>
                    <div className="input-block text-center">
                      <GoogleLoginButton />
                    </div>
                    
                  </div>
                  {/* /Account Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
