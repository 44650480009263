//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : GoogleLoginButton.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240705     Jecyline     create GoogleLoginButton.jsx
//* 20240717     Jecyline     amend handleLoginSuccess(), get REACT_APP_GOOGLE_CLIENT_ID from env, change plugin to react-oauth/google
//
//***************************************************************************/
import React from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { googleicon } from "../Routes/ImagePath";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { api_url } from "../base_urls";

const GoogleLoginButton = () => {
  const navigate = useNavigate();

  const handleLoginSuccess = async (response) => {
    console.log('Google login response:', response);

    fetch(api_url + '/auth/google', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${response.credential}` // Include the token in the Authorization header
      },
      body: JSON.stringify({
        token: response.credential, // Send the token as part of the request body
        action: 'login', // Or 'login' based on your requirements
      }),
    })
    .then(res => res.json())
    .then(data => {
      // console.log(data);
      if(data.error){
        console.log('Error during backend request:', data);
      } else {
        console.log('Google login successful:', data);
        navigate('/otp', { state: { email: data.email } });
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const handleLoginFailure = (error) => {
    console.error('Login Failed:', error);
  };
  
  
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        onFailure={handleLoginFailure}
        style={{ display: 'none' }} // Hide the default button
        >
        {({ onClick }) => (
          <button className="account-icon-button" onClick={onClick}>
            <img src={googleicon} alt="Google" className="account-img-icon"/>
            Login with Google
          </button>
        )}
      </GoogleLogin>
    </GoogleOAuthProvider>
  );
  
  };
  

export default GoogleLoginButton;


