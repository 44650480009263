//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : Affiliate.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (15 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240715     Jecyline     create Affiliate.jsx
//* 20240718     Jecyline     language selection module
//
//***************************************************************************/
import React, { useEffect, useState } from 'react';
import { api } from '../../../services/api';
import Breadcrumbs from "../../../components/Breadcrumbs";
import useAxiosInterceptors from '../../../hooks/useAxiosInterceptors';
import { AffiliateImage, qrsample } from "../../../Routes/ImagePath";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'react-qr-code';
import { frontend_url } from "../../../base_urls";
import { Modal, Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Affiliate = () => {
  // Call the custom hook to set up interceptors
  useAxiosInterceptors(); 
  const { t } = useTranslation();

  const [referralLink, setReferralLink] = useState('');
  const [recommendedRecord, setRecommendedRecord] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchAffiliateInfo();
  }, []);

  const fetchAffiliateInfo = async () => {
    try {
      const response = await api.get(`/affiliate`);
      const data = response.data;

      // Assuming `my_referral_code` is the correct field name
      setReferralLink(`${frontend_url}/register/${data.user.my_referral_code}`);
      setRecommendedRecord(data.affiliate_records || []);
      console.log(data.affiliate_records);

    } catch (error) {
      console.error('Error fetching company info:', error);
    }
  };

  const totalCredits = recommendedRecord.reduce((sum, record) => sum + record.credits_earned, 0);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink)
      .then(() => alert('Copied to clipboard!'))
      .catch(() => alert('Failed to copy link'));
  };

  const shareOnSocialMedia = (platform) => {
    const encodedLink = encodeURIComponent(referralLink);
    let url;

    switch (platform) {
      case 'whatsapp':
        url = `https://wa.me/?text=${encodedLink}`;
        break;
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
        break;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?url=${encodedLink}`;
        break;
      case 'linkedin':
        url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedLink}`;
        break;
      default:
        return;
    }

    window.open(url, '_blank');
  };


  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid affiliate">
          <Breadcrumbs
            maintitle={t('Affiliate')}
            title={t('Dashboard')}
            subtitle={t('Affiliate')}
          />

          <div className="row">
            {/* Join Our Affiliate Program */}
            <div className="col-12 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body p-0">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="text-container">
                      <h1 className="text-center card-title">{t('Join Our Affiliate Program')}</h1>
                      <p className="text-center card-subtitle">{t('Refer us to your friends to earn more credits')}.</p>
                      <p className="text-center card-subtitle">{t('Share the love and introduce us')}.</p>
                    </div>
                    <div className="image-container">
                      <img src={AffiliateImage} alt="Affiliate Program Image" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Join Our Affiliate Program end */}
          </div>

          <div className="row">
            {/* Scan QR Code */}
            <div className="col-12 col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body p-5">
                  <h3 className="card-title text-center">
                    {t('Scan QR Code')}{" "}
                  </h3>
                  <p className="text-center card-subtitle">
                    {t('Ask your friend to open their camera on their smart phone and aim it at the QR code below. This will load your invite link on their browser.')}
                  </p>
                  <div className="mt-4 qr-image-container">
                    {referralLink && (
                      <QRCode 
                        value={referralLink} // The referral link to encode
                        size={256} // Size of the QR Code
                        level="H"  // Error correction level (H = High)
                        fgColor="#000000" // QR Code color
                        bgColor="#ffffff" // Background color
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Scan QR Code end */}

            {/* Share Your Link */}
            <div className="col-12 col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body p-5">
                  <h3 className="card-title text-center">
                    {t('Share Your Link')}{" "}
                  </h3>
                  <p className="text-center card-subtitle">
                    {t('Share your invite URL link with your friends.')}{" "}
                  </p>
                  <div className="referral-link-container mt-5 mb-4 d-flex items-center justify-content-between">
                    <input
                      type="text"
                      readOnly
                      value={referralLink}
                      className="referral-link"
                    />
                    <button onClick={copyToClipboard} className="copy-button">
                      <FontAwesomeIcon 
                        icon={faCopy}
                      />
                    </button>
                  </div>
                  <button 
                    type="button" 
                    className="mx-auto btn btn-primary affiliate-btn"
                    onClick={() => setShowModal(true)}
                  >
                    {t('Share')}
                  </button>
                </div>
              </div>
            </div>
            {/* Share Your Link end */}
          </div>

          <div className="row">
            {/* Recommended Records */}
            <div className="col-12 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    {t('Recommended Records')}{" "}
                  </h3>
                  <Table bordered hover className="referral-table">
                    <thead >
                      <tr style={{ backgroundColor: '#f0f0f0' }}>
                        <th>{t('Name')}</th>
                        <th>{t('Company Name')}</th>
                        <th>{t('Credits Earned')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recommendedRecord.map((record, index) => (
                        <tr key={index}>
                          <td>{record.name ?? '-'}</td>
                          <td>{record.company_name ?? '-'}</td>
                          <td>{record.credits_earned ?? '0'}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="2" className="text-end fw-bold">{t('Total')} {t('Credits Earned')}{' '}</td>
                        <td className="fw-bold">{totalCredits}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            {/* Recommended Records end */}
          </div>
        </div>
        {/* /Page Content end */}
        {/* Modal */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Share Your Link')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column">
              <Button 
                variant="outline-success" 
                onClick={() => { shareOnSocialMedia('whatsapp'); setShowModal(false); }}
                className="mb-2"
              >
                {t('Share on ')}{t('WhatsApp')}
              </Button>
              <Button 
                variant="outline-primary" 
                onClick={() => { shareOnSocialMedia('facebook'); setShowModal(false); }}
                className="mb-2"
              >
                {t('Share on ')}{t('Facebook')}
              </Button>
              <Button 
                variant="outline-info" 
                onClick={() => { shareOnSocialMedia('twitter'); setShowModal(false); }}
                className="mb-2"
              >
                {t('Share on ')}{t('Twitter')}
              </Button>
              <Button 
                variant="outline-dark" 
                onClick={() => { shareOnSocialMedia('linkedin'); setShowModal(false); }}
                className="mb-2"
              >
                {t('Share on ')}{t('LinkedIn')}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Modal end */}
      </div>
    </>
  );
};

export default Affiliate;
