//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : LanguageSelector.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (18 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240718     jecyline     create LanguageSelector.jsx
//
//***************************************************************************/
import React, { useState } from 'react';
import { useLanguage } from '../../context/LanguageContext';

import {
  lnEnglish,
  lnFrench,
  lnGerman,
  lnSpanish,
  lnChina
} from "../../Routes/ImagePath";

const LanguageSelector = () => {
  const { language, changeLanguage } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
    setIsOpen(false);
  };

  const getLanguageClass = (lng) => {
    return language === lng ? 'active' : '';
  };

  return (
    <div className="language-selector">
      <div className="custom-dropdown">
        <div className="selected-language" onClick={toggleDropdown}>
          <img src={language === 'en' ? lnEnglish : lnChina} alt={language === 'en' ? 'English' : '中文'} className="flag-icon"/>
          {/* <span>{language === 'en' ? 'English' : '中文'}</span> */}
          <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'}`} />
        </div>
        {isOpen && (
          <div className="dropdown-options">
            <div className={`dropdown-option ${getLanguageClass('en')}`} onClick={() => handleLanguageChange('en')}>
              <img src={lnEnglish} alt="English" className="flag-icon"/> EN
              {/* <span>English</span> */}
            </div>
            <div className={`dropdown-option ${getLanguageClass('zh')}`} onClick={() => handleLanguageChange('zh')}>
              <img src={lnChina} alt="Chinese" className="flag-icon" /> CN
              {/* <span>中文</span> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageSelector;
