//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : index.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      Function features short description
//* 20240730     Jecyline     Get chat list and use lazy load, add function for toggle sidebar, search chat room name, 
//
//***************************************************************************/
import React, { Suspense, useState, useEffect } from 'react';

import { Link } from "react-router-dom";
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'; // Import the custom hook
import { api } from '../../../../services/api';
import { useDispatch } from 'react-redux';
import { setFileChatList } from '../../../../store/slices/fileManagerSlice';
import { useTranslation } from 'react-i18next';

const FileManagerChatSidebar = React.lazy(() => import('./fileManagerChatSidebar'));
const FileManagerView = React.lazy(() => import('./fileManagerView'));

const FileManager = () => {
  useAxiosInterceptors(); 
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    // Call the API with the search query
    const fetchResults = async () => {
      try {
        // Replace with your API endpoint
        const dataRes = await api.post('/file-manager/chats', {name: searchName});
        dispatch(setFileChatList(dataRes.data.chats));

      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    fetchResults();
  }, [searchName, dispatch]);

  const handleInputChange = (event) => {
    setSearchName(event.target.value);
  };

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className={`file-wrap ${isSidebarOpen ? '' : 'file-sidebar-toggle'}`}>
                <div className="file-sidebar">
                {/* <div className="file-sidebar"> */}
                  <div className="file-header justify-content-center">
                    <span>{t('Chats')}</span>
                    <Link 
                      to="#" 
                      className="file-side-close"
                      onClick={toggleSidebar}
                    >
                      <i className="fa-solid fa-xmark" />
                    </Link>
                  </div>
                  <form className="file-search">
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="fa-solid fa-magnifying-glass" />
                      </div>
                      <input
                        type="text"
                        className="form-control rounded-pill"
                        placeholder={`${t('Search')}`}
                        value={searchName}
                        onChange={handleInputChange}
                        autoComplete='off'
                      />
                    </div>
                  </form>
                  <Suspense fallback={<div>{t('Loading...')}</div>}>
                    <FileManagerChatSidebar />
                  </Suspense>

                  {/* <div className="file-pro-list">
                    <div className="file-scroll">
                      <ul className="file-menu">
                        <li className="active">
                          <Link to="#">All Chats</Link>
                        </li>
                        <li>
                          <Link to="chat">JBL Headphone</Link>
                        </li>
                      </ul>
                      <div className="show-more">
                        <Link to="#">Show More</Link>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="file-cont-wrap">
                  <div className="file-cont-inner">
                    <div className="file-cont-header">
                      <div className="file-options">
                        <Link
                          to="#"
                          id="file_sidebar_toggle"
                          className="file-sidebar-toggle"
                          onClick={toggleSidebar}
                        >
                          <i className="fa-solid fa-bars" />
                        </Link>
                      </div>
                      <span>{t('File Manager')}</span>
                      <div className="file-options">
                        <span className="btn-file">
                          {/* <input type="file" className="upload" />
                          <i className="fa-solid fa-upload" /> */}
                        </span>
                      </div>
                    </div>
                    <div className="file-content">
                      {/* <form className="file-search">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="fa-solid fa-magnifying-glass" />
                          </div>
                          <input
                            type="text"
                            className="form-control rounded-pill"
                            placeholder="Search"
                          />
                        </div>
                      </form> */}
                      <Suspense fallback={<div>{t('Loading...')}</div>}>
                        <FileManagerView />
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
};

export default FileManager;
