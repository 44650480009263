//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : chatApi.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (29 Aug 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240829     Jecyline     Create chatApi.jsx
//* 20240830     Jecyline     add fetchChatMemory
//* 20240905     Jecyline     add createChatApi(), getChatListApi(), getChatDetailApi(), updateReactionApi(), updateImageSatisfactionApi(), getNextApi(), aiImageRecognitionApi(), aiProofreaderApi(), aiTranslatorApi(), aiBackgroundRegenerateApi(), aiBackgroundRemoveApi(), aiImageMaskApi(), aiImageInpaintingApi()
//* 20240905     Jecyline     add uploadImageApi(), updateChatDetailApi() and amend aiImageFluxApi(), storeReplyApi(), storePromptApi(), aiImageRecognitionApi()
//* 20240911     Jecyline     add updateChatContentFlagApi(), amend aiImageRecognitionApi(): change prompt, amend aiImageFluxApi(): get image size if any
//
//***************************************************************************/
import axios from 'axios';
import { api } from '../services/api'; //apiClient
import API_ENDPOINTS from './endpoints';

// Omninexus Backend API ---------------------------------------------- //
export const createChatApi = (chat_name) => {
  const formData = new FormData();
  formData.append('chat_name', chat_name);

  return api.post(API_ENDPOINTS.CHAT(), formData);
};

export const getChatListApi = () => {
  return api.get(API_ENDPOINTS.CHAT());
};

export const getChatDetailApi = (chatId, page) => {
  return api.get(API_ENDPOINTS.CHAT_GET_DETAILS(chatId, page));
};

export const fetchChatMemoryApi = (chatId) => {
  return api.get(API_ENDPOINTS.CHAT_FETCH_CHAT_MEMORY(chatId));
};

export const updateReactionApi = (chatDetailId, reply_reaction) => {
  const formData = new FormData();
  formData.append('reply_reaction', reply_reaction);

  return api.post(API_ENDPOINTS.CHAT_UPDATE_REACTION(chatDetailId), formData);
};

export const updateImageSatisfactionApi = (chatDetailId, image_satisfaction) => {
  const formData = new FormData();
  formData.append('image_satisfaction', image_satisfaction);

  return api.post(API_ENDPOINTS.CHAT_UPDATE_IMAGE_SATISFACTION(chatDetailId), formData);
};

export const getNextApi = (prompt) => {
  const formData = new FormData();
  formData.append('prompt', prompt);

  return api.post(API_ENDPOINTS.CHAT_GET_NEXT_API(), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadImageApi = (chatId, promptData) => {
  const formData = new FormData();
  formData.append('prompt', promptData.prompt ?? '');
  formData.append('file_prompt_url', promptData.file_prompt_url);
  formData.append('prompt_date', promptData.promptDate);
  formData.append('prompt_time', promptData.promptTime);
  formData.append('edited_prompt', promptData.edited_prompt ?? '');

  return api.post(API_ENDPOINTS.CHAT_UPLOAD_IMAGE(chatId), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateChatDetailApi = (chatId, updateData) => {

  return api.post(API_ENDPOINTS.CHAT_UPDATE_DETAIL(chatId), updateData);
};

export const updateChatContentFlagApi = (chatId, updateFlag) => {
  return api.post(API_ENDPOINTS.CHAT_UPDATE_CONTENT_FLAG(chatId), {updateFlag: updateFlag});
};

export const storePromptApi = (chatId, promptData) => {
  const formData = new FormData();
  formData.append('stage', promptData.stage ?? '');
  formData.append('prompt', promptData.prompt ?? '');
  formData.append('prompt_url', promptData.prompt_url ?? '');
  formData.append('file_prompt_url', promptData.file_prompt_url ?? '');
  formData.append('prompt_date', promptData.promptDate);
  formData.append('prompt_time', promptData.promptTime);

  formData.append('prompt_score', promptData.promptScore ?? '');
  formData.append('prompt_api_called', promptData.promptApiCalled ?? '');

  formData.append('edited_prompt', promptData.editedPrompt ?? '');
  formData.append('translated_prompt', promptData.translatedPrompt ?? '');
  formData.append('translation_language', promptData.translationLanguage ?? '');
  formData.append('mask_url', promptData.maskURL ?? '');

  return api.post(API_ENDPOINTS.CHAT_STORE_PROMPT(chatId), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};


export const storeReplyApi = (chatId, replyData) => {
  const formData = new FormData();
  formData.append('chat_detail_id', replyData.chat_detail_id ?? '');
  formData.append('reply', replyData.reply ?? '');
  formData.append('reply_url', replyData.reply_url ?? '');
  formData.append('edited_reply', replyData.edited_reply ?? '');
  formData.append('reply_role', replyData.reply_role ?? '');

  return api.post(API_ENDPOINTS.CHAT_STORE_REPLY(chatId), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
// Omninexus Backend API end------------------------------------------- //
// Omninexus Marvis API ----------------------------------------------- //
export const aiImageRecognitionApi = (image_url, prompt = '') => {
  console.log('image_url', image_url);
  const parameter = {
    url: image_url,
    prompt: 'Describe this image in detail, only output the image prompt for image generation',
  };
  return axios.post(API_ENDPOINTS.MARVIS_RECOGNITION(), parameter);
};

export const aiChatbotApi = (chatMemory) => {
  // return axios.post(`${process.env.REACT_APP_MARVIS_AI}${process.env.REACT_APP_MARVIS_CHATBOT}`,
  return axios.post(API_ENDPOINTS.MARVIS_AI_CHATBOT(), {
    messages: chatMemory,
  });
};

export const aiProofreaderApi = (text) => {
  const parameter = {
    text: text,
  };
  return axios.post(API_ENDPOINTS.MARVIS_PROOFREADER(), parameter);
};

export const aiTranslatorApi = (text) => {
  const parameter = {
    text: text,
    from_lang: "auto",
    to_lang: "english",
    ai_mode: true
  };
  return axios.post(API_ENDPOINTS.MARVIS_TRANSLATOR(), parameter);
};

export const aiImageFluxApi = (prompt, image_url, params) => {
  const parameter = {
    prompt: prompt,
    image_size: params.size === '' ? "landscape_16_9" : params.size,
    num_inference_steps: 35,
    guidance_scale: 8,
    prompt_magic: true,
    num_images: 1,
    enable_safety_checker: true,
    sync_mode: false,
    photo_real: false,
    seed: null
  };
  if(image_url !== ''){
    parameter.image_url = image_url;
  }
  console.log('aiImageFluxApi parameter:', parameter)
  return axios.post(API_ENDPOINTS.MARVIS_IMAGE_FLUX(), parameter);
};

export const aiBackgroundRegenerateApi = (url, text) => {
  const parameter = {
    url: url,
    text: text,
    bg_remove: true,
  };
  return axios.post(API_ENDPOINTS.MARVIS_BACKGROUND_GENERATE(), parameter);
};

export const aiBackgroundRemoveApi = (url) => {
  return axios.post(API_ENDPOINTS.MARVIS_BACKGROUND_REMOVE(), url);
};

export const aiImageMaskApi = (url, object) => {
  const parameter = {
    url: url,
    object: object,
  };
  return axios.post(API_ENDPOINTS.MARVIS_IMAGE_MASK(), parameter);
};

export const aiImageInpaintingApi = (prompt, url, mask_url) => {
  const parameter = {
    prompt: prompt,
    image: url,
    mask: mask_url,
    strength: 1.0,
    num_inference_steps: 30,
    guidance_scale: 8,
    height: 1024,
    width: 1024,
    turbo: false,
    sync_mode: false,
    seed: null,
    enable_safety_checker: true,
    num_outputs: 4,
    output_format: "jpg",
    output_quality: 100
  };
  return axios.post(API_ENDPOINTS.MARVIS_IMAGE_INPAINTING(), parameter);
};


// Omninexus Marvis API end-------------------------------------------- //
