//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : FeedbackModal.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (05 August 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240805     Jecyline     create FeedbackModal.jsx for feedback module
//* 20240806     Jecyline     add checking for ratings and set error before submit form
//* 20240808     Jecyline     amend rating points to 5
//
//***************************************************************************/
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../assets/scss/modals/_feedback-modal.scss';

const NewChatModal = ({ show, onClose, onCreate }) => {
  const { t } = useTranslation();

  const [ratings, setRatings] = useState('');
  const [didHelp, setDidHelp] = useState('');
  const [improvementSuggestion, setImprovementSuggestion] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (ratings === '') {
      setErrorMessage(`${t('Please rate us.')}`);
      setTimeout(() => setErrorMessage(''), 10000 );
    } else if (ratings !== '' && didHelp && improvementSuggestion) {
      onCreate({ ratings, didHelp, improvementSuggestion });
      onClose();
      setRatings('');
      setDidHelp('');
      setImprovementSuggestion('');
    } 
  };

  const handleRatingsCheckboxChange = (value) => {
    setRatings(value);
  };

  const handleDidHelpDropdownChange = (e) => {
    setDidHelp(e.target.value);
  };

  const handleimprovementSuggestionChange = (e) => {
    setImprovementSuggestion(e.target.value);
  };


  return (
    <div>
      {/* Backdrop */}
      <div className={`modal-backdrop ${show ? 'show' : ''}`} onClick={onClose}></div>

      {/* Modal */}
      <div className={`modal custom-modal ${show ? 'show d-block' : 'd-none'} fade`} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t('Feedback Form')}</h5>
              <button type="button" className="btn-close" onClick={onClose}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="input-block mb-3">
                  <label>{t('Rate the overall performance of our chat.')}</label>
                  <div className="rating-checkboxes my-2">
                    {[...Array(5).keys()].map(num => (
                      <label
                        key={num + 1}
                        className={`rating-checkbox rating-${num + 1} ${ratings === (num + 1).toString() ? 'checked' : ''}`}
                      >
                        <input
                          type="checkbox"
                          value={num + 1}
                          checked={ratings === (num + 1).toString()}
                          onChange={() => handleRatingsCheckboxChange((num + 1).toString())}
                        />
                        <span>{num + 1}</span>
                      </label>
                    ))}
                  </div>
                  {errorMessage && (
                    <span className="text-danger d-block">
                      {errorMessage}
                    </span>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label>{t('Did Marvis help you achieve your desired ads?')}</label>
                  <select 
                    className="form-control" 
                    value={didHelp} 
                    onChange={handleDidHelpDropdownChange} 
                    required
                  >
                    <option value="">{t('Select...')}</option>
                    <option value="1">{t('Yes')}</option>
                    <option value="2">{t('No')}</option>
                  </select>
                </div>
                <div className="input-block mb-3">
                  <label>{t('What would you like us to improve?')}</label>
                  <textarea 
                    className="form-control" 
                    value={improvementSuggestion} 
                    onChange={handleimprovementSuggestionChange} 
                    required
                    rows="4"
                  />
                </div>
                <div className="submit-section mt-2">
                  <button type="submit" className="btn btn-primary">
                    {t('Submit')}
                  </button>
                </div>
              </form>
            </div>  
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewChatModal;
