//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : Terms.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign   Description
//* ------------ ----   ------------------------------------------------------
//* 20240725     Glynnis Added Terms 
//
//***************************************************************************/
import React from 'react';

const Terms = () => {
  return (
    <>
      <div className="page-wrapper">
        
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="small-container">
            <div className="inner-header text-center">
              <h1>SOFTWARE SUBSCRIPTION TERMS AND CONDITIONS</h1>
            </div>
            <div className="inner-content">
              <p>The software and related documentation that you are about to access (the "Software") is offered by OMNI NEXUS SDN. BHD. (1558152-T) (“the Software Provider”), for your use in accordance with the terms and conditions below.</p>
              <p>By clicking the "ACCEPT TERMS AND CONDITIONS" box during the download, installation or activation of the Software, you represent that you have read, understood and agree to be bound by the terms and conditions herein stipulated (the "License"). If you do not accept these terms and conditions then you are not granted a license and are not authorized to access, download, install or use the Software.</p>
              <h5>1. DEFINITIONS</h5>
              <p>"Software" means the software products, including associated documentation, made available to you for download and installation and which are licensed to you by the Software Provider pursuant to this License.</p>
              <h5>2. RIGHT AND TITLE TO THE SOFTWARE</h5>
              <p>The Software Provider owns all right, title and interest, including all intellectual property rights, in and to the Software and all copies of all or any part thereof are and remain with the Software Provider and/or other parties to whom the Software Provider has obligations to protect such title and ownership rights (“the Other Owner”) and;</p>
              <p>The Licensed Programs are comprised of the Software Provider´s and the Other Owner´s trade secrets and proprietary information, whether or not any portion thereof is or may be copyrighted or patented.</p>
              <h5>3. LICENSE</h5>
              <p>Subject to the terms and conditions herein stipulated, the Software Provider hereby grants to you a personal, non-exclusive and non-transferable licence to internally use the Software solely for your internal business purposes in connection with the use of the services as provided in the Software (the “Purpose”).</p>
              <p>FOR CLARIFICATION AND AVOIDANCE OF ANT DOUBT, THIS LICENSE DOES NOT AUTHORIZE YOU TO USE THE SOFTWARE FOR COMMERCIAL PURPOSES.</p>
              <h5>4. USE RESTRICTIONS</h5>
              <p>You shall not use the Software for any purposes beyond the scope of the access granted in herein. Without limiting the generality of the foregoing, you shall not at any time, directly or indirectly, and shall not permit any person to:-</p>
              <p>(i) copy, modify, or create derivative works of the Software's services or documentation, in whole or in part;</p>
              <p>(ii) Rent, lease, sell, license, sublicense, assign, distribute, publish, transfer, or otherwise make available the Software's services or documentation;</p>
              <p>(iii) Reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to any software component of the Software, in whole or in part;</p>
              <p>(iv) Remove any proprietary notices from the Software's services or documentation;</p>
              <p>(v) Use the Software's services or documentation in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or other right of any person, or that violates any applicable law;</p>
              <p>(vi) Publicly disclose the results of any benchmarking of the Software against any competing products or services; or</p>
              <p>(vii) Use the Software for the purpose of developing a product or service that may be competitive with the Software.</p>
              <h5>5. SUSPENSION</h5>
              <p>Notwithstanding anything to the contrary herein, the Software Provider may suspend you and/or your authorised user's access to any portion or all of the Software if:-</p>
              <p>(i) the Software Provider reasonably determines that (a) there is a threat or attack on any of the Software's Services; (b) you or any of your authorised user's use of the Software disrupts or poses a security risk to the Software or to any other customer or vendor of the Software; (c) you or any of your authorised user, is using the Software's services or documentation for fraudulent or illegal activities; (d) subject to applicable law, you have ceased to continue business in the ordinary course, made an assignment for the benefit of creditors or similar disposition of assets, or become the subject of any bankruptcy, reorganization, liquidation, dissolution, or similar proceeding; or (e) the Software Provider's provision of the Software is prohibited by applicable law; or</p>
              <p>(ii) any vendor of the Software Provider has suspended or terminated the Software Provider's access to or use of any third-party services or products required to enable you to access the Software (any such suspension described in subclause (i) or (ii), a “Service Suspension”).</p>
              <p>The Software Provider will have no liability for any damage, liabilities, losses (including any loss of data or profits), or any other consequences that you may incur as a result of a Service Suspension.</p>
              <h5>6. CUSTOMER RESPONSIBILITIES</h5>
              <p>You are responsible and liable for all uses of the Software's services and documentation resulting from access provided to/by you, directly or indirectly, whether such access or use is permitted by or in violation of the terms and conditions herein.</p>
              <p>You acknowledge and agree that you must cease all use of the Software and that any and all copies of the Software that you have in your possession must be destroyed immediately upon expiration of the License.</p>
              <h5>7. CONFIDENTIALITY AND SECURITY</h5>
              <p>You acknowledge and agree that the Software constitutes confidential information.</p>
              <p>You agree to (i) use the Software only as necessary to achieve the Purpose; to (ii) maintain Software in secure premises to prevent any unauthorized person from obtaining any part thereof and (iii) protect the Software by using the same degree of care as you use to protect your own confidential information of like nature, but no less than reasonable care, to prevent the unauthorized dissemination or publication and unauthorized use of the Software. In the event you are required to disclose the Software in connection with any legal, judicial or administrative proceedings or government investigation or otherwise required by law, then you will promptly notify the Software Provider and allow a reasonable time for the Software Provider to seek a protective or other court or administrative order from the appropriate court or government agency. Thereafter, the Software may be disclosed to the extent required by law and subject to any protective order or other court or administrative order which may then apply.</p>
              <p>From time to time during the Term, either Party may disclose or make available to the other Party information about its business affairs, products, confidential intellectual property, trade secrets, third-party confidential information, and other sensitive or proprietary information, whether orally or in written, electronic, or other form or media/in written or electronic form or media that is marked or otherwise designated as “confidential”, “proprietary”, or something similar at the time of disclosure or within a reasonable period of time thereafter and/or would be considered confidential or proprietary by a reasonable person given the nature of the information or the circumstances of its disclosure (collectively, “Confidential Information“).</p>
              <p>Without granting any right or license, the obligations of the parties hereunder will not apply to any material or information that: (i) is or becomes a part of the public domain through no act or omission by the receiving party; (ii) is independently developed by the other party without use of the disclosing party's Confidential Information; (iii) is rightfully obtained from a third party without any obligation of confidentiality; or (iv) is already known by the receiving party without any obligation of confidentiality prior to obtaining the Confidential Information from the disclosing party. In addition, neither party will be liable for disclosure of Confidential Information if made in response to a valid order of a court or authorized agency of government, provided that notice is promptly given to the disclosing party so that the disclosing party may seek a protective order and engage in other efforts to minimize the required disclosure. The parties shall cooperate fully in seeking such protective order and in engaging in such other efforts.</p>
              <p>Nothing in these Terms and Conditions will be construed to convey any title or ownership rights to the Software or other Confidential Information to you or to any patent, copyright, trademark, or trade secret embodied therein, or to grant any other right, title, or ownership interest to the Software Provider's Confidential Information. Neither party shall, in whole or in part, sell, lease, license, assign, transfer, or disclose the Confidential Information to any third party and shall not copy, reproduce or distribute the Confidential Information except as expressly permitted in by the Software Provider. Each party shall take every reasonable precaution, but no less than those precautions used to protect its own Confidential Information, to prevent the theft, disclosure, and the unauthorized copying, reproduction or distribution of the Confidential Information.</p>
              <p>Each party agrees at all times to use all reasonable efforts, but in any case no less than the efforts that each party uses in the protection of its own Confidential Information of like value, to protect Confidential Information belonging to the other party.</p>
              <p>Each party acknowledges that any unauthorized disclosure or use of the Confidential Information would cause the other party imminent irreparable injury and that such party will be entitled to, in addition to any other remedies available at law or in equity, temporary, preliminary, and permanent injunctive relief in the event the other party does not fulfill its obligations under this Section.</p>
              <p>Unless otherwise expressly agreed in writing, all suggestions, solutions, improvements, corrections, and other contributions provided by you regarding the Software or other Software Provider materials provided to you will be owned by Software Provider, and you hereby agrees to assign any such rights to the Software Provider. Nothing herein will preclude the Software Provider from using in any manner or for any purpose it deems necessary, the know-how, techniques, or procedures acquired or used by the Software Provider in the performance of services hereunder.</p>
              <h5>8. WARRANTY DISCLAIMER</h5>
              <p>All of the Software's application and/or documentation provided hereunder, including without limitation the services, is provided “as is” and the Software Provider hereby disclaims all warranties, whether express, implied, statutory or otherwise. The Software Provider specifically disclaims all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and all warranties arising from course of dealing, usage or trade practice, the Software Provider makes no warranty of any kind that the result of the use thereof, will meet customer's or any other person's requirements, operate without interruption, achieve any intended result, be compatible or work with any software, system or other services, or be secure, accurate, complete, free of harmful code or error free.</p>
              <h5>9. LIMITATION OF LIABILITY</h5>
              <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THE SOFTWARE PROVIDER BE LIABLE UNDER ANY THEORY OF LIABILITY, WHETHER IN AN EQUITABLE, LEGAL, OR COMMON LAW ACTION ARISING HEREUNDER FOR CONTRACT, STRICT LIABILITY, INDEMNITY, TORT (INCLUDING NEGLIGENCE), SOLICITORS FEES AND COSTS, OR OTHERWISE, FOR DAMAGES WHICH, IN THE AGGREGATE, EXCEED THE AMOUNT OF THE FEES PAID BY YOU AS CUSTOMER FOR THE SERVICES WHICH GAVE RISE TO SUCH DAMAGES.</p>
              <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THE SOFTWARE PROVIDER BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND AND HOWEVER CAUSED INCLUDING, BUT NOT LIMITED TO, SOLICITORS FEES AND COSTS, BUSINESS INTERRUPTION OR LOSS OF PROFITS, BUSINESS OPPORTUNITIES, OR GOODWILL.</p>
              <p>THE FOREGOING LIMITATIONS APPLY EVEN IF NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGE AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY.</p>
              <p>YOU ACKNOWLEDGE AND AGREE THAT THE DISCLAIMER OF WARRANTY AND THE LIMITATIONS OF LIABILITY PROVIDED HEREIN CONSTITUTE AN ESSENTIAL PART OF THIS TERMS &amp; CONDITIONS. NO USE OF THE SOFTWARE IS AUTHORIZED HEREUNDER EXCEPT UNDER THIS DISCLAIMER.</p>
              <h5>10. INDEMNIFICATION</h5>
              <p>The Software Provider will have no liability for any claim of infringement based on: (i) Software which has been modified by parties other than the Software Provider where the infringement claim would not have occurred in the absence of such modification; (ii) your use of the Software in conjunction with data where use with such data gave rise to the infringement claim; or (iii) your use of the Software outside the permitted scope of these Terms.</p>
              <p>Should the Software become, or in the Software Provider's opinion is likely to become, the subject of a claim of infringement, the Software Provider may, at its option, (i) obtain the right for you as customer to continue using the Software, (ii) replace or modify the Software so it is no longer infringing or reduces the likelihood that it will be determined to be infringing, or (iii) if neither of the foregoing options is commercially reasonable, terminate the access and Use of the Software.</p>
              <p>You agree to defend, indemnify, and hold the Software Provider and its officers, directors, employees, consultants, and agents harmless from and against any and all damages, costs, liabilities, expenses (including, without limitation, reasonable Solicitors' fees), and settlement amounts incurred in connection with any claim arising from or relating to your: (i) breach of any of its obligations set forth herein as customer or authorised user; (ii) your gross negligence or willful misconduct; (iii) actual or alleged use of the Software in violation of these Terms or applicable law by you or your suthorised userss; (iv) any actual or alleged infringement or misappropriation of third party intellectual property rights arising from data provided to the Software Provider by you or otherwise inputted into the Software, whether by you, your Authorized User or otherwise; and/or (v) any violation by you or your Authorized Users, of any terms, conditions, agreements or policies of any third party service provider.</p>
              <h5>11. MISCELLANEOUS</h5>
              <p>The Software Provider will not be liable to you for any delay or failure of the Software Provider to perform its obligations hereunder if such delay or failure arises from any cause or causes beyond the reasonable control of the Software Provider. Such causes will include, but are not limited to, acts of God, floods, fires, loss of electricity or other utilities, or delays by you in providing required resources or support or performing any other requirements hereunder.</p>
              <p>Each provision of these Terms is a separately enforceable provision. If any provision of these Terms is determined to be or becomes unenforceable or illegal, such provision will be reformed to the minimum extent necessary in order for these Terms to remain in effect in accordance with its terms as modified by such reformation.</p>
              <p>These Terms are governed by the substantive and procedural laws of Malaysia, and both parties agree to submit to the exclusive jurisdiction of, and venue in, the courts in the Peninsular of Malaysia in any dispute arising out of or relating to these Terms.</p>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </>


  );
}
export default Terms;