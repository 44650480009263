//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : FacebookSignUpButton.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240705     Jecyline     create FacebookSignUpButton.jsx
//* 20240717     Jecyline     amend responseFacebook(), get REACT_APP_FACEBOOK_APP_ID from env
//
//***************************************************************************/
import React from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props' ;
import { api_url } from "../base_urls";
import { facebookicon } from "../Routes/ImagePath";


const FacebookSignUpButton = () => {
  const navigate = useNavigate();

  const responseFacebook = async (response) => {
    if(response.accessToken) {
      // User is logged in
      console.log('Successfully sign up in', response);

      fetch(api_url + '/auth/facebook', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${response.accessToken}` // Include the token in the Authorization header
        },
        body: JSON.stringify({
          token: response.accessToken, // Send the token as part of the request body
          action: 'register', // Or 'login' based on your requirements
        }),
      })
      .then(res => res.json())
      .then(data => {
        // console.log(data);
        if(data.error){
          console.log('Error during backend request:', data);
        } else {
          console.log('Facebook login successful:', data);
          navigate('/otp', { state: { email: data.email } });
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    } else {
      console.log('Login failed', response);
    }
  };
  
  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      autoLoad={false}
      fields="email"
      callback={responseFacebook}
      render={renderProps => (
        <button onClick={renderProps.onClick} className="account-icon-button">
          <img src={facebookicon} alt="Facebook" className="account-img-icon"/>
          Sign up with Facebook
        </button>
      )}
    />
  );
};
  

export default FacebookSignUpButton;


