//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : LanguageContext.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (18 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240718     jecyline     create LanguageContext.jsx
//
//***************************************************************************/
import React, { useEffect, createContext, useState, useContext } from 'react';
import i18n from '../i18n';

// Create a context for the current language and a function to change it
export const LanguageContext = createContext();

// Custom hook to consume the LanguageContext
export const useLanguage = () => useContext(LanguageContext);

// Provider component to wrap your app and provide the language state and function to change it
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || i18n.language || 'en'; // Initialize with localStorage or i18n language or default to 'en'
  });

  useEffect(() => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }, [language]);

  const changeLanguage = (lng) => {
    setLanguage(lng);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
