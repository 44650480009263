//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : chatList.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign   Description
//* ------------ ----   ------------------------------------------------------
//* 20240701     Glynnis Function features short description
//
//***************************************************************************/
import React from "react";
import { Link } from "react-router-dom";

const ChatList = () => {

  return (
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="title">Chat List</div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-name">
                        <div className="card-body">
                            {/* <Link to="/chat-test" title="chatlist">
                              1. JBL Headphone
                            </Link>
                            <br/><br/> */}
                            <Link to="/chat" title="new chat">
                              New chat session
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatList;
