//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : index.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      create index.jsx
//* 20240724     Jecyline     import and add chat reducer
//* 20240730     Jecyline     import and add file_manager reducer
//
//***************************************************************************/
import { configureStore } from "@reduxjs/toolkit";
// import userReducer from "./slices/userSlice"; // Make sure this import is correct
// import chatReducer from './slices/chatSlice'; // Import the chat reducer
// import fileManagerReducer from './slices/fileManagerSlice'; // Import the chat reducer
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';  // Your root reducer
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

// const reducer = {
//   user: userReducer, // Access the 'reducer' property of the userSlice
//   chat: chatReducer, // Add the chat reducer
//   file_manager: fileManagerReducer, // Add the chat reducer
// };

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false, // Disables thunk if you're using saga instead
    }).concat(sagaMiddleware),
  devTools: true,
});

sagaMiddleware.run(rootSaga);

export default store;
