//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : Profile.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      Function features short description
//* 20240718     Jecyline     language selection module
//
//***************************************************************************/
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import ProfileTab from "./ProfileTab";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();

  // const userData = {
  //   id: 1,
  //   name: "John Doe",
  //   role: "UI/UX Design Team",
  //   jobTitle: "Web Designer",
  //   employeeId: "FT-0001",
  //   dateOfJoin: "1st Jan 2023",
  //   phone: "9876543210",
  //   email: "johndoe@example.com",
  //   birthday: "24th July",
  //   address: "1861 Bayonne Ave, Manchester Township, NJ, 08759",
  //   gender: "Male",
  //   supervisor: {
  //     name: "Jeffery Lalor",
  //     avatar: "assets/img/profiles/avatar-16.jpg",
  //   },
  // };
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t('Profile')}
            title={t('Dashboard')}
            subtitle={t('Profile')}
            modal="#add_indicator"
            name="Add New"
          />
          
          {/* Profile Info Tab */}
          <ProfileTab />
        </div>
      </div>
    </>
  );
};

export default Profile;
