//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : CompanyInfo.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (12 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240712     Jecyline     create CompanyInfo.jsx
//* 20240715     Jecyline     postal code to be numeric, company email change to email, email is readonly
//* 20240716     Jecyline     fix wrong placeholder for company services/products, add validation check for country code, fix serverError bug
//* 20240717     Jecyline     get REACT_APP_CAPTCHA_SITE_KEY from env
//* 20240718     Jecyline     language selection module
//* 20240822     Jecyline     use tarun api to get country list
//
//***************************************************************************/
import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import axios from "axios";
import { api } from '../../../services/api';
import Breadcrumbs from "../../../components/Breadcrumbs";
import { facebookicon } from "../../../Routes/ImagePath";
import Select from 'react-select';
// import countryList from 'react-select-country-list';
import ReCAPTCHA from 'react-google-recaptcha'
import useAxiosInterceptors from '../../../hooks/useAxiosInterceptors'; // Import the custom hook
import { useTranslation } from 'react-i18next';

const CompanyInfo = () => {
  // Call the custom hook to set up interceptors
  useAxiosInterceptors(); 
  const { t } = useTranslation();

  // Define the validation schema using Yup
  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required(`${t('company name')}${t(' is required')}`),
    company_address: Yup.string().required(`${t('company address')}${t(' is required')}`),
    company_postal_code: Yup.string()
      .matches(/^\d+$/, `${t('company postal code')}${t(' must be numeric')}`)
      .required(`${t('company postal code')}${t(' is required')}`),
    company_state: Yup.string().required(`${t('company state')}${t(' is required')}`),
    company_country_code: Yup.string().required(`${t('Country')}${t(' is required')}`),
    company_phone_no: Yup.string().required(`${t('company phone no')}${t(' is required')}`),
    // company_email: Yup.string()
    //   .email('Invalid email format')
    //   .required('Company email is required'),
    company_website: Yup.string()
      .required(`${t('company website')}${t(' is required')}`)
      .url(`${t('errors.INVALID_URL_FORMAT')}`),
    company_service_or_product: Yup.string().required(`${t('company services/products')}${t(' is required')}`),
    company_short_desc: Yup.string().required(`${t('company short description')}${t(' is required')}`),
  });

  // Initialize the form with react-hook-form and Yup resolver
  const { 
    control, 
    handleSubmit, 
    setValue, 
    formState: { errors } 
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: '',
      status: '',
      email: '',
      company_name: '',
      company_address: '',
      company_postal_code: '',
      company_state: '',
      company_country_code: '',
      company_phone_no: '',
      company_website: '',
      company_short_desc: '',
      company_service_or_product: '',
    }
  });

  const navigate = useNavigate();
  const recaptcha = useRef()

  const [serverErrors, setServerErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaError, setCaptchaError] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_MARVIS_AI}/countries`);
        // console.log('response',response);

        const countryList = response.data.all_countries;
        
        const countries = countryList.map(country => ({
          value: country.cca2,
          label: country.name.common,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
        
        setCountryOptions(countries);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCountryList();

  }, []);

  useEffect(() => {
    fetchCompanyInfo();
  }, []);

  const fetchCompanyInfo = async () => {
    try {
      const response = await api.get(`/company`);
      const data = response.data;

      setValue('id', data.id);
      setValue('status', data.status);
      setValue('email', data.email || '');
      setValue('company_name', data.company_name || '');
      setValue('company_address', data.company_address || '');
      setValue('company_postal_code', data.company_postal_code || '');
      setValue('company_state', data.company_state || '');
      setValue('company_country_code', data.company_country_code || '');
      setValue('company_phone_no', data.company_phone_no || '');
      setValue('company_website', data.company_website || '');
      setValue('company_short_desc', data.company_short_desc || '');
      setValue('company_service_or_product', data.company_service_or_product || '');

    } catch (error) {
      console.error('Error fetching company info:', error);
    }
  };

  const onSubmit = async (data) => {
    try { // verify captcha
      const captchaToken = recaptcha.current.getValue()
      if (!captchaToken) {
        setCaptchaError(`${t('Please verify the reCAPTCHA')}`);
        setTimeout(() => setCaptchaError(''), 5000);
      } else {
        await api.post(`/verify-captcha`, {captchaToken});
        
        try { //update company info
          await api.put(`/company`, data);

          recaptcha.current.reset();
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setSuccessMessage(`${t('Company info')} ${t(' updated successfully')}`); // Call the success message handler
          setTimeout(() => setSuccessMessage(''), 5000);
        } catch (error) {
          recaptcha.current.reset();
          if (error.response && error.response.data) {
            setServerErrors(error.response.data);
          } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setErrorMessage(`${t('An error occurred while updating company info')}`);
            setTimeout(() => setErrorMessage(''), 5000); 
          }
        }
      }
    } catch (error) {  
      console.log(error);    
      setCaptchaError(error.response.data.error);
      setTimeout(() => setCaptchaError(''), 5000);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid company-info">
          <Breadcrumbs
            maintitle={t('Company Info')}
            title={t('Dashboard')}
            subtitle={t('Company Info')}
          />
          {/* Company Info Form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}

            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}

            <div className="row">
              {/* Company Basic Information */}
              <div className="col-12 col-md-6 d-flex">
                <div className="card profile-box flex-fill">
                  <div className="card-body">
                    <h3 className="card-title">
                      {t('Company Basic Information')}{" "}
                    </h3>

                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Company Name')}</label>
                      <Controller
                        name="company_name"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`form-control`}
                            type="text"
                            placeholder={`${t('Enter your ')} ${t('company name')}`}
                            {...field}
                            autoComplete="false"
                          />
                        )}
                      />
                      <span className="text-danger d-block">
                        {errors.company_name?.message}
                        {serverErrors.company_name && <p>{serverErrors.company_name[0]}</p>}
                      </span>
                    </div>

                    {/* Address Group */}
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Address')}</label>
                      <Controller
                        name="company_address"
                        control={control}
                        render={({ field }) => (
                          <textarea
                            className={`form-control`}
                            placeholder={`${t('Enter your ')} ${t('company address')}`}
                            {...field}
                            rows={2}
                            autoComplete="off"
                          />
                        )}
                      />
                      <span className="text-danger d-block">
                        {errors.company_address?.message}
                        {serverErrors.company_address && <p>{serverErrors.company_address[0]}</p>}
                      </span>
                    </div>

                    <div className="input-block mb-3">
                      <Controller
                        name="company_postal_code"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`form-control`}
                            type="text"
                            placeholder={`${t('Enter your ')} ${t('company postal code')}`}
                            {...field}
                            autoComplete="false"
                          />
                        )}
                      />
                      <span className="text-danger d-block">
                        {errors.company_postal_code?.message}
                        {serverErrors.company_postal_code && <p>{serverErrors.company_postal_code[0]}</p>}
                      </span>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="input-block mb-3">
                          <Controller
                            name="company_state"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control`}
                                type="text"
                                placeholder={`${t('Enter your ')} ${t('company state')}`}
                                {...field}
                                autoComplete="false"
                              />
                            )}
                          />
                          <span className="text-danger d-block">
                            {errors.company_state?.message}
                            {serverErrors.company_state && <p>{serverErrors.company_state[0]}</p>}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="input-block mb-3">
                          <Controller
                            name="company_country_code"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={countryOptions}
                                placeholder={`${t('Select your country')}`}
                                classNamePrefix="react-select"
                                onChange={(selectedOption) => {
                                  // Pass only the selected value (country code) to the backend
                                  field.onChange(selectedOption ? selectedOption.value : null);
                                }}
                                value={countryOptions.find(option => option.value === field.value)}
                                isClearable  // Allows clearing the selection
                              />
                            )}
                          />
                          <span className="text-danger d-block">
                            {errors.company_country_code?.message}
                            {serverErrors.company_country_code && <p>{serverErrors.company_country_code[0]}</p>}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* Address Group end */}

                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Phone No')}</label>
                      <Controller
                        name="company_phone_no"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`form-control`}
                            type="text"
                            placeholder={`${t('Enter your ')} ${t('company phone no')}`}
                            {...field}
                            autoComplete="false"
                          />
                        )}
                      />
                      <span className="text-danger d-block">
                        {errors.company_phone_no?.message}
                        {serverErrors.company_phone_no && <p>{serverErrors.company_phone_no[0]}</p>}
                      </span>
                    </div>

                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Email')}</label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`form-control`}
                            type="text"
                            {...field}
                            autoComplete="false"
                            disabled
                          />
                        )}
                      />
                      <span className="text-danger d-block">
                        {errors.email?.message}
                        {serverErrors.email && <p>{serverErrors.email[0]}</p>}
                      </span>
                    </div>

                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Website')}</label>
                      <Controller
                        name="company_website"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`form-control`}
                            type="text"
                            placeholder={`${t('Enter your ')} ${t('company website')}`}
                            {...field}
                            autoComplete="false"
                          />
                        )}
                      />
                      <span className="text-danger d-block">
                        {errors.company_website?.message}
                        {serverErrors.company_website && <p>{serverErrors.company_website[0]}</p>}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* Company Basic Information end */}

              <div className="col-12 col-md-6">
                {/* Company Details */}
                <div className="row">
                  <div className="col-sm-12 d-flex">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">
                          {t('Company Details')}{" "}
                        </h3>

                        <div className="input-block mb-3">
                          <label className="col-form-label">{t('Short Description')}</label>
                          <Controller
                            name="company_short_desc"
                            control={control}
                            render={({ field }) => (
                              <textarea
                                className={`form-control`}
                                placeholder={`${t('Enter your ')} ${t('company short description')}`}
                                {...field}
                                rows={6}
                                autoComplete="off"
                              />
                            )}
                          />
                          <span className="text-danger d-block">
                            {errors.company_short_desc?.message}
                            {serverErrors.company_short_desc && <p>{serverErrors.company_short_desc[0]}</p>}
                          </span>
                        </div>

                        <div className="input-block mb-3">
                          <label className="col-form-label">{t('Company services/products')}</label>
                          <Controller
                            name="company_service_or_product"
                            control={control}
                            render={({ field }) => (
                              <textarea
                                className={`form-control`}
                                placeholder={`${t('List down your company services/products')}`}
                                {...field}
                                rows={6}
                                autoComplete="off"
                              />
                            )}
                          />
                          <span className="text-danger d-block">
                            {errors.company_service_or_product?.message}
                            {serverErrors.company_service_or_product && <p>{serverErrors.company_service_or_product[0]}</p>}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Company Details end */}
                {/* Connection Facebook Accounts */}
                <div className="row">
                  <div className="col-sm-12 d-flex">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">
                          {t('Connection Facebook Accounts')}{" "}
                        </h3>
                        <button type="button" className="account-icon-button">
                          <img src={facebookicon} alt="Facebook" className="account-img-icon"/>
                          {t('Facebook')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Connection Facebook Accounts end */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 d-flex">
                <div className="card profile-box flex-fill">
                  <div className="card-body">
                    {/* CAPTCHA */}
                    <div className="input-block mb-3">
                      <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} />
                      
                        {captchaError && (
                          <span className="text-danger d-block">
                            {captchaError}
                          </span>
                        )}
                    </div>
                    <button type="submit" className="btn btn-primary company-btn">{t('Submit')}</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/* Company Info Form end */}
        </div>
        {/* /Page Content end */}
      </div>
    </>
  );
};

export default CompanyInfo;
