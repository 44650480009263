//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : index.js
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      Function features short description
//* 20240718     Jecyline     import ./i18n, LanguageProvider
//* 20240823     Jecyline     import ./services/bugsnag
//
//***************************************************************************/
import React from "react";
import ReactDOM from "react-dom/client";
import Approuter from "./Routes/Approuter";
import './i18n';
import { LanguageProvider } from './context/LanguageContext';
import Bugsnag from './services/bugsnag';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/line-awesome.min.css";
import "./assets/scss/main.scss";
import "./assets/css/material.css";

// const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <ErrorBoundary> */}
      <LanguageProvider>
        <Approuter />
      </LanguageProvider>
    {/* </ErrorBoundary> */}
  </React.StrictMode>
);
