//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : FacebookLoginButton.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240705     Jecyline     create FacebookLoginButton.jsx
//* 20240717     Jecyline     get REACT_APP_FACEBOOK_APP_ID from env
//* 20240805     Jecyline     set feedbackModalShown to localStorage
//
//***************************************************************************/
import React from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props' ;
import { api_url } from "../base_urls";
import { facebookicon } from "../Routes/ImagePath";

const FacebookLoginButton = () => {
  const navigate = useNavigate();

  const responseFacebook = async (response) => {
      if (response.accessToken) {
        // User is logged in
        console.log('Successfully logged in', response);
  
        try {  
          // Send the token to your backend for further validation or login/signup
          const responseData = await axios.post(api_url + '/login/facebook/callback', {
            accessToken: response.accessToken
          });

          const { access_token, token_type, expires_in } = responseData.data;

          localStorage.setItem('access_token', access_token); // Save access token
          localStorage.setItem('token_type', token_type); // Save the token type
          localStorage.setItem('expires_in', expires_in); // Save token expiration time
          localStorage.setItem('feedbackModalShown', 'false');

          navigate('/dashboard');

        } catch (error) {
          console.error('Error during backend request:', error);
          // alert('Error during Facebook login. Please try again.');
        }
      
      } else {
        // User is not logged in
        console.log('Login failed', response);
        // alert('Facebook login failed. Please try again.');
      }
    };
  
    return (
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={false}
        fields="email"
        callback={responseFacebook}
        render={renderProps => (
          <button onClick={renderProps.onClick} className="account-icon-button">
            <img src={facebookicon} alt="Facebook" className="account-img-icon"/>
            Login with Facebook
          </button>
        )}
        // cssClass="my-facebook-button"
        // icon="fa-facebook"
      />
    );
  };
  

export default FacebookLoginButton;


