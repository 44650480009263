//****************************************************************************
//* Filename  : chatSagas.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (29 Aug 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240829     Jecyline     Create chatSagas.jsx
//* 20240830     Jecyline     add fetchChatMemory
//* 20240905     Jecyline     add handleCreateChat(), handleChatClick(), handleFetchMoreChatDetails(), handleUpdateReaction(), handleUpdateImageSatisfaction(), handleStorePrompt(), handleStoreReply(), handleSelectionMode()
//* 20240905     Jecyline     add handleUploadImage(), handleCamera(), handleChatbot(), handleTextToImage(), handleChangeBackground(), handleRemoveBackground(), handleMasking(), handleInpainting()
//* 20240906     Jecyline     get selection option from chatConstants
//* 20240909     Jecyline     fix bug for handleCamera() & handleTextToImage(): set full image url
//* 20240911     Jecyline     update brain flow, fix date format issue(use moment)
//* 20240912     Jecyline     integrate brain flow 70%
//
//***************************************************************************/
import { call, put, takeLatest, delay, all, select } from 'redux-saga/effects';
import i18n from 'i18next';
import { backend_url } from '../../base_urls';
import { getFullImageUrl } from '../../utils/urlUtils';
import moment from 'moment';
import { 
  createChatAction,
  chatClickAction,
  fetchMoreChatDetailsAction,
  fetchChatMemoryAction,
  updateReactionAction,
  storePromptAction,
  selectionModeAction,
  uploadImageAction,
  cameraAction,
} from '../actions/chatActions';

import { 
  appendCurrentChatDetails, 
  appendChatMemory, 
  setIsSufficientCredit,
  editCurrentChatData,
  setChatLoader,
  editCurrentChatDetails,
  setBottomRefTrigger,
  setCurrentChatData,
  setCurrentChatDetails,
  setCurrentPage,
  setHasMore,
  setIsLoadingChatDetails,
  setChatStage,
  prependCurrentChatDetails,
  setIsLoadingFirstPage,
  setChatList,
  setChatSelection,
  editChatStage
} from '../slices/chatSlice';

import { 
  selectChatMemoryForCurrentChat,
  selectCurrentPage,
  selectHasMore,
  selectIsLoadingChatDetails,
  selectIsLoadingFirstPage,
  selectChatStage,
  selectChatSelectionForCurrentChat,
  selectCurrentChatData
} from '../selectors/chatSelectors';

import { 
  createChatApi,
  getChatListApi,
  getChatDetailApi,
  fetchChatMemoryApi,
  updateReactionApi,
  updateImageSatisfactionApi,
  storePromptApi,
  uploadImageApi,
  updateChatDetailApi,
  getNextApi,
  storeReplyApi,
  updateChatContentFlagApi,
  aiImageRecognitionApi,
  aiChatbotApi,
  aiProofreaderApi,
  aiTranslatorApi,
  aiImageFluxApi,
  aiBackgroundRegenerateApi,
  aiBackgroundRemoveApi,
  aiImageMaskApi,
  aiImageInpaintingApi,
} from '../../api/chatApi';

import {
  targetList,
  targetOptionsList,
} from '../../utils/chatContants'
// Handle Backend API ---------------------------------------------- //
function* handleCreateChat(action) {
  console.log('chatSagas: handleCreateChat', action);

  const { chat_name } = action.payload;

  try {
    yield put(setCurrentChatDetails({
      date: '',
      newDetails: []
    }));

    const chatResponse = yield call(createChatApi, chat_name);
    const { chat, chatDetail } = chatResponse.data;

    // Update the current chat data
    yield put(setCurrentChatData(chat));
  
    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
    yield put(setCurrentChatDetails({
      date: today,
      newDetails: Array.isArray(chatDetail) ? chatDetail : [chatDetail]
    }));
    // Fetch the updated chat list
    const chatListResponse = yield call(getChatListApi);

    const groupedChats = {
      today: chatListResponse.data.today_chats,
      yesterday: chatListResponse.data.yesterday_chats,
      last7Days: chatListResponse.data.last_7_days_chats,
      olderDays: chatListResponse.data.older_days_chats
    };
    
    yield put(setChatList(groupedChats));
    yield put(setIsLoadingChatDetails(false));
    yield put(setHasMore(false));
    yield put(setChatStage({ chat_id: chat.id }));
    yield put(editChatStage({
      chat_id: chat.id,
      updates: {
        stage: '1',
        subStage: 'initial_prompt',
        status: 'start',
      }
    }));
    yield call(handleFetchChatMemory, { payload: { chatId: chat.id } });

  } catch (error) {
    console.error('handleCreateChat Saga Error:', error);

  }
}

function* handleChatClick(action) {
  console.log('chatSagas: handleChatClick', action);

  const { chatId } = action.payload;

  try {
    const currentChatData = yield select(selectCurrentChatData);

    if(currentChatData && chatId === currentChatData.id){
      yield put(setBottomRefTrigger(Date.now()));

    } else {

    
      yield put(setCurrentChatData(null));
      yield put(setCurrentChatDetails({
        date: '',
        newDetails: []
      }));

      yield put(setIsLoadingFirstPage(false));
      yield put(setIsLoadingChatDetails(false));
      yield put(setCurrentPage(0));
      yield put(setHasMore(true));
      yield put(setChatStage({ chat_id: chatId })); 

      yield call(handleFetchMoreChatDetails, { payload: { chatId } });
    }
  } catch (error) {
    console.error('handleChatClick Saga Error:', error);

  }
}

function* handleFetchMoreChatDetails(action) {
  console.log('chatSagas: handleFetchMoreChatDetails', action);

  const { chatId } = action.payload;
  const hasMore = yield select(selectHasMore);
  const currentPage = yield select(selectCurrentPage);
  const isLoadingChatDetails = yield select(selectIsLoadingChatDetails);
  const isLoadingFirstPage = yield select(selectIsLoadingFirstPage);
  // console.log('chatId', chatId);
  // console.log('currentPage', currentPage);
  // console.log('hasMore', hasMore);
  // console.log('isLoadingChatDetails', isLoadingChatDetails);
  // console.log('isLoadingFirstPage', isLoadingFirstPage);
  if(hasMore && !isLoadingFirstPage && !isLoadingChatDetails) {
    try {
      
      if(currentPage === 0) {
        yield put(setIsLoadingFirstPage(true));
      } else {
        yield put(setIsLoadingChatDetails(true));
        yield delay(200); // Optional delay
      }
      
      const newPage = Number(currentPage) + 1;
      const response = yield call(getChatDetailApi, chatId, newPage);
  
      const { chat, chatdetails, current_page, last_page, selected_copy, selected_image_url, selected_image_desc } = response.data;
  
      // Initial load
      if(currentPage === 0) {
        yield put(setCurrentChatData(chat));

        const stageList = yield select(selectChatStage, chat.id);
        const stageData = stageList[chat.id];

        if(stageData.status === 'pending'){

          //set copy and image content
          yield put(editChatStage({
            chat_id: chat.id,
            updates: {
              selected_copy: selected_copy,
              selected_image_url: selected_image_url,
              selected_image_desc: selected_image_desc,
            }
          }));

          if(selected_copy !== '' && selected_image_url === ''){ //have copy, no image
            yield put(editChatStage({
              chat_id: chat.id,
              updates: {
                stage: '3',
                subStage: 'initial_image',
                status: 'start',
              }
            }));
          } else if(selected_copy !== '' && selected_image_url !== ''){ //have both
            yield put(editChatStage({
              chat_id: chat.id,
              updates: {
                stage: '1',
                subStage: 'initial_prompt',
                status: 'complete',
              }
            }));
            yield put(setChatLoader({ 
              chat_id: chat.id, 
              isLoading: false, 
              isListening: false, 
              message: "" 
            }));
          } else { 
            yield put(editChatStage({
              chat_id: chat.id,
              updates: {
                stage: '1',
                subStage: 'initial_prompt',
                status: 'start',
              }
            }));
          }
        }
      }

      yield put(prependCurrentChatDetails({ chat_id: chat.id, chatData: chatdetails }));
      yield put(setCurrentPage(newPage));

      if (Number(current_page) >= Number(last_page)) {
        yield put(setHasMore(false));
      }
    } catch (error) {
      console.error('initialChatDetails Saga Error:', error);
    } finally {

      if(currentPage === 0) {
        yield put(setBottomRefTrigger(Date.now()));
        yield delay(200); // Optional delay

        yield put(setIsLoadingFirstPage(false));
      } else {
        yield put(setIsLoadingChatDetails(false));
      }

    }
  }
}

function* handleFetchChatMemory(action) {
  console.log('chatSagas: handleFetchChatMemory', action);

  const { chatId } = action.payload;

  try {
    const response = yield call(fetchChatMemoryApi, chatId);
    // console.log('response', response);
    const { chat_memory } = response.data;

    yield put(appendChatMemory({
      chat_id: chatId,
      chat_memory: Array.isArray(chat_memory) ? chat_memory : [chat_memory],
    }));
  } catch (error) {
    console.error('handleFetchChatMemory Saga Error:', error);
  }
}

function* handleUpdateReaction(action) {
  console.log('chatSagas: handleUpdateReaction', action);

  const { chatDetailId, reply_reaction } = action.payload;

  try {
    const response = yield call(updateReactionApi, chatDetailId, reply_reaction);
    // console.log('Response:', response);

    const { chatDetail, updateData } = response.data;
    
    yield put(editCurrentChatDetails({
      chat_id: chatDetail.chat_id,
      chat_detail_id: chatDetail.id,
      updatedData: updateData
    }));

  } catch (error) {
    console.error('handleUpdateReaction Saga Error:', error);
  }
}

function* handleUpdateImageSatisfaction(action) {
  console.log('chatSagas: handleUpdateImageSatisfaction', action);

  const { chatDetailId, image_satisfaction } = action.payload;

  try {
    const response = yield call(updateImageSatisfactionApi, chatDetailId, image_satisfaction);
    // console.log('Response:', response);

    const { chatDetail, updateData } = response.data;
    
    yield put(editCurrentChatDetails({
      chat_id: chatDetail.chat_id,
      chat_detail_id: chatDetail.id,
      updatedData: updateData
    }));

  } catch (error) {
    console.error('handleUpdateReaction Saga Error:', error);
  }
}


function* handleStorePrompt(action) {
  console.log('chatSagas: handleStorePrompt', action);

  const { chatId, promptData } = action.payload;

  try{
    const stageList = yield select(selectChatStage, chatId);
    const stageData = stageList[chatId];
    console.log('handleStorePrompt: stageData', stageData)

    let updatedPromptData = {
      ...promptData,
      stage: stageData.stage ?? '',
    };

    switch (stageData.stage) {
      case '1': //Initial
        
        if(stageData.subStage === 'initial_prompt') {
          // Proofread API
          const proofreadResponse = yield call(aiProofreaderApi, promptData.prompt);
          console.log('proofreadResponse', proofreadResponse);

          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: proofreadResponse.data.corrected_text ?? '',
          };

          // Translator API
          const translatorResponse = yield call(aiTranslatorApi, updatedPromptData.editedPrompt);
          console.log('translatorResponse', translatorResponse);
          const translatedToData = translatorResponse.data.to;

          const nextApiResponse = yield call(getNextApi, translatedToData.text.value ?? '');
          console.log('nextApiResponse', nextApiResponse);
          const reponseNextApiData = nextApiResponse.data.next_api_data;
  
          updatedPromptData = {
            ...updatedPromptData, // Spread the already updated prompt data
            translatedPrompt: reponseNextApiData.prompt ?? '',
            translationLanguage: translatedToData.language.iso ?? [],
            promptApiCalled: reponseNextApiData.nextApi ?? process.env.REACT_APP_MARVIS_CHATBOT,
            promptScore: reponseNextApiData.score ?? 0,
          };
        } else if (stageData.subStage === 'image_to_copy_prompt') {
          updatedPromptData = {
            ...updatedPromptData,
            promptApiCalled: process.env.REACT_APP_MARVIS_CHATBOT,
            editedPrompt: 'Based on this image description "'+stageData.selected_image_desc+'" and this text description "'+promptData.prompt+'", generate a copywriting.',
          };
        } else if (stageData.subStage === 'selected_copy_addon') {
          updatedPromptData = {
            ...updatedPromptData,
            promptApiCalled: process.env.REACT_APP_MARVIS_CHATBOT,
            editedPrompt: 'Based on this copy "'+stageData.selected_copy+'" and this text description "'+promptData.prompt+'", generate a copywriting.',
          };
        }
        break;
      case '2': //Chat
        let edited_prompt = '';
        if(stageData.subStage === 'strategy') {
          if(stageData.subStageOption === 'custom_strategy'){
            //combine initial_prompt + current prompt
            edited_prompt = stageData.initial_reply + ' ' + promptData.prompt;
          } else { // options: driving_conversion, consultancy, time_limited_offer, discounts
            //edited_prompt = write me a strategize copywriting base on {stageData.subStageOption.value } on my copywriting {initial_reply}
            const strategy = targetOptionsList.copywriting_strategy.find(option => option.id === stageData.subStageOption);
            const strategyValue = strategy ? strategy.value:'';
            edited_prompt = 'write me a strategize copywriting base on '+strategyValue+' on my copywriting '+stageData.initial_reply;
          }
          
        } else if (stageData.subStage === 'revise') {
          edited_prompt = promptData.prompt;
        }
        updatedPromptData = {
          ...updatedPromptData,
          editedPrompt: edited_prompt,
          promptApiCalled: process.env.REACT_APP_MARVIS_CHATBOT,
        };
        break;
      case '3': //Image
        if(stageData.subStage === 'initial_image'){
          if(stageData.status === 'start'){
            // Proofread API
            const proofreadResponse = yield call(aiProofreaderApi, promptData.prompt);
            console.log('proofreadResponse', proofreadResponse);

            updatedPromptData = {
              ...updatedPromptData,
              editedPrompt: proofreadResponse.data.corrected_text ?? '',
            };

            // Translator API
            const translatorResponse = yield call(aiTranslatorApi, updatedPromptData.editedPrompt);
            console.log('translatorResponse', translatorResponse);
            const translatedToData = translatorResponse.data.to;

            const nextApiResponse = yield call(getNextApi, translatedToData.text.value ?? '');
            console.log('nextApiResponse', nextApiResponse);
            const reponseNextApiData = nextApiResponse.data.next_api_data;

            updatedPromptData = {
              ...updatedPromptData, // Spread the already updated prompt data
              translatedPrompt: reponseNextApiData.prompt ?? '',
              translationLanguage: translatedToData.language.iso ?? [],
              promptApiCalled: reponseNextApiData.nextApi ?? process.env.REACT_APP_MARVIS_CHATBOT,
              promptScore: reponseNextApiData.score ?? 0,
            };
          } else if (stageData.status === 'ongoing') { //initial_image + ongoing
            let edited_prompt = promptData.prompt;

            if (stageData.image_size !== '' && stageData.image_style !== '') {
              // from prompt generate image 
              edited_prompt = 'Imagine this into an image "'+stageData.initial_prompt+'" in the style of '+stageData.image_style;
  
              // from isCopy suggest image
              if(stageData.selected_copy !== ''){
                edited_prompt = 'Imagine this into an image "'+stageData.selected_copy+'" in the style of '+stageData.image_style;
              }
            } else {
              //from any other: do nothing
            }
          
            updatedPromptData = {
              ...updatedPromptData,
              editedPrompt: edited_prompt,
              promptApiCalled: process.env.REACT_APP_MARVIS_IMAGE_FLUX,
            };
          }
        } else if (stageData.subStage === 'edit_image') {
          //
        } else if (stageData.subStage === 'selected_image_revise') {
          let edited_prompt = promptData.prompt;

          if (stageData.image_size !== '' && stageData.image_style !== '') {
            edited_prompt = 'Imagine this into an image "' + stageData.selected_copy + '" add on "' + stageData.initial_prompt + '" in the style of '+ stageData.image_style;
          }

          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: edited_prompt,
            promptApiCalled: process.env.REACT_APP_MARVIS_IMAGE_FLUX,
          };
        }
        break;
      default:
        break;
    }

    console.log('handleStorePrompt: updatedPromptData', updatedPromptData);

    // Store prompt API
    const promptReponse = yield call(storePromptApi, chatId, updatedPromptData);
    const { chatDetail, chat_memory, sufficient_credit } = promptReponse.data;
    console.log('handleStorePrompt: promptReponse', promptReponse);
    console.log('handleStorePrompt: stageData', stageData)

    // Add new chat detail
    yield put(appendCurrentChatDetails(Array.isArray(chatDetail) ? chatDetail : [chatDetail]));
    yield put(setBottomRefTrigger(Date.now()));

    // update last activtime
    yield put(editCurrentChatData({ field: 'last_active_datetime', value: chatDetail.updated_at }));

    // Append chat memory if any
    if(chat_memory) {
      yield put(appendChatMemory({
        chat_id: chatId,
        chat_memory: Array.isArray(chat_memory) ? chat_memory : [chat_memory],
      }));
    }

    yield delay(100); // Optional delay

    if (sufficient_credit) {
      if(stageData.status === 'complete') { //have copy and image
        // if is a copy
        if(stageData.stage === '1' && stageData.subStage === 'initial_prompt' && chatDetail.prompt_score > 0){
          if (chatDetail.prompt_api_called === process.env.REACT_APP_MARVIS_CHATBOT || chatDetail.prompt_api_called === process.env.REACT_APP_MARVIS_IMAGE_FLUX) {
            yield put(setChatLoader({ 
              chat_id: chatDetail.chat_id, 
              isLoading: false, 
              isListening: false, 
              message: "" 
            }));
  
            yield put(setChatSelection({ 
              chat_id: chatDetail.chat_id, 
              chat_detail_id: chatDetail.id, 
              isDisplay: true, 
              target: targetList.revise_complete_ads,
              title: "It seems like you are starting a new advertisement for another product, as your digital marketing assistant. I would advise you to 'start a new chat' for each product. Or do you want to revise anything from our discussion so far?",
              options: targetOptionsList.revise_complete_ads,
              animationClass: 'slide-up'
            }));
          }
        } else { //if is normal chat
          yield call(handleChatbot, chatDetail);
        }
      } else { // start/ongoing/revise
        switch (stageData.stage) { 
          case '1': //Initial
            if(stageData.subStage === 'initial_prompt') {

              if(stageData.status !== 'complete'){
                //set initial prompt
                yield put(editChatStage({
                  chat_id: chatId,
                  updates: {
                    initial_prompt: chatDetail.edited_prompt,
                    chat_detail_id: chatDetail.id,
                    status: 'ongoing'
                  }
                }));
              }

              //condition to call either chatbot / textToImage api
              if (chatDetail.prompt_api_called === process.env.REACT_APP_MARVIS_IMAGE_FLUX){
                yield put(editChatStage({
                  chat_id: chatId,
                  updates: {
                    stage: '3',
                    subStage: 'initial_image',
                    initial_prompt: chatDetail.edited_prompt,
                    chat_detail_id: chatDetail.id
                  }
                }));

                if(stageData.selected_image_url !== ''){ // if have selected image
                  yield put(setChatLoader({ 
                    chat_id: chatDetail.chat_id, 
                    isLoading: false, 
                    isListening: false, 
                    message: "" 
                  }));
                  yield put(setChatSelection({ 
                    chat_id: chatDetail.chat_id, 
                    chat_detail_id: chatDetail.id, 
                    isDisplay: true, 
                    target: targetList.revise_complete_ads,
                    title: "It seems like you are starting a new advertisement for another product, as your digital marketing assistant. I would advise you to 'start a new chat' for each product. Or do you want to revise anything from our discussion so far?",
                    options: targetOptionsList.revise_complete_ads,
                    animationClass: 'slide-up'
                  }));
                } else { //no selected image 
                  yield put(setChatLoader({ 
                    chat_id: chatDetail.chat_id, 
                    isLoading: false, 
                    isListening: false, 
                    message: "" 
                  }));
                  yield put(setChatSelection({ 
                    chat_id: chatDetail.chat_id, 
                    chat_detail_id: chatDetail.id, 
                    isDisplay: true, 
                    target: targetList.image_sizes,
                    title: 'Tell me what is your image size',
                    options: targetOptionsList.image_sizes,
                    animationClass: 'slide-up'
                  }));
                }
                
              } else {
                yield call(handleChatbot, chatDetail);
              }
            } else if (stageData.subStage === 'image_to_copy_prompt' || stageData.subStage === 'selected_copy_addon') {
              yield call(handleChatbot, chatDetail);
            }
            break;
          case '2': //Chat
            if(stageData.subStage === 'strategy' || stageData.subStage === 'revise') {
              yield call(handleChatbot, chatDetail);
            }
            break;
          case '3': //Image
            if(stageData.subStage === 'initial_image') {
              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  initial_prompt: chatDetail.edited_prompt,
                  chat_detail_id: chatDetail.id
                }
              }));

              if(stageData.status === 'start') { // initial_image + start
                if(chatDetail.prompt_score > 0.5 && chatDetail.prompt_api_called === process.env.REACT_APP_MARVIS_IMAGE_FLUX) {
                  yield put(setChatLoader({ 
                    chat_id: chatDetail.chat_id, 
                    isLoading: false, 
                    isListening: false, 
                    message: "" 
                  }));
                  yield put(setChatSelection({ 
                    chat_id: chatDetail.chat_id, 
                    chat_detail_id: chatDetail.id, 
                    isDisplay: true, 
                    target: targetList.image_sizes,
                    title: 'Tell me what is your image size',
                    options: targetOptionsList.image_sizes,
                    animationClass: 'slide-up'
                  }));
                } else {
                  yield put(setChatLoader({ 
                    chat_id: chatDetail.chat_id, 
                    isLoading: false, 
                    isListening: false, 
                    message: "" 
                  }));
                  yield put(setChatSelection({ 
                    chat_id: chatDetail.chat_id, 
                    chat_detail_id: chatDetail.id, 
                    isDisplay: true, 
                    target: targetList.generate_image,
                    title: 'Do you want to generate image based on the above content?',
                    options: targetOptionsList.generate_image,
                    animationClass: 'slide-up'
                  }));
                }
                
              } else if (stageData.status === 'ongoing') { //initial image + ongoing

                if(stageData.selected_image_url !== ''){ //have selected image
                  yield put(setChatLoader({ 
                    chat_id: chatDetail.chat_id, 
                    isLoading: false, 
                    isListening: false, 
                    message: "" 
                  }));
                  yield put(setChatSelection({ 
                    chat_id: chatDetail.chat_id, 
                    chat_detail_id: chatDetail.id, 
                    isDisplay: true, 
                    target: targetList.revise_complete_ads,
                    title: "It seems like you are starting a new advertisement for another product, as your digital marketing assistant. I would advise you to 'start a new chat' for each product. Or do you want to revise anything from our discussion so far?",
                    options: targetOptionsList.revise_complete_ads,
                    animationClass: 'slide-up'
                  }));
                } else if (stageData.image_size !== '' && stageData.image_style !== ''){  //have size and style
                  yield put(setChatLoader({ 
                    chat_id: chatDetail.chat_id, 
                    isLoading: true, 
                    isListening: false, 
                    message: `${i18n.t("Hang tight! Your image is on its way!")}`
                  }));
                  yield call(handleTextToImage, chatDetail);

                  
                } else if ( stageData.image_size === ''){
                  yield put(setChatLoader({ 
                    chat_id: chatDetail.chat_id, 
                    isLoading: false, 
                    isListening: false, 
                    message: "" 
                  }));
                  yield put(setChatSelection({ 
                    chat_id: chatDetail.chat_id, 
                    chat_detail_id: chatDetail.id, 
                    isDisplay: true, 
                    target: targetList.image_sizes,
                    title: 'Tell me what is your image size',
                    options: targetOptionsList.image_sizes,
                    animationClass: 'slide-up'
                  }));
                } else if (stageData.image_style === '') {
                  yield put(setChatLoader({ 
                    chat_id: chatDetail.chat_id, 
                    isLoading: false, 
                    isListening: false, 
                    message: "" 
                  }));
                  yield put(setChatSelection({ 
                    chat_id: chatDetail.chat_id, 
                    chat_detail_id: chatDetail.id, 
                    isDisplay: true, 
                    target: targetList.image_styles,
                    title: 'Tell me what kind of style you want your image to be like?',
                    options: targetOptionsList.image_styles,
                    animationClass: 'slide-up'
                  }));
                }
              }
            }  else if (stageData.subStage === 'edit_image') {
              //
            } else if (stageData.subStage === 'selected_image_revise'){
              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  initial_prompt: chatDetail.edited_prompt,
                  chat_detail_id: chatDetail.id
                }
              }));

              if(stageData.image_size !== '' && stageData.image_size !== ''){ // have style and size
                yield put(setChatLoader({ 
                  chat_id: chatDetail.chat_id, 
                  isLoading: true, 
                  isListening: false, 
                  message: `${i18n.t("Hang tight! Your image is on its way!")}`
                }));
                yield call(handleTextToImage, chatDetail);
              } else {
                yield put(setChatLoader({ 
                  chat_id: chatDetail.chat_id, 
                  isLoading: false, 
                  isListening: false, 
                  message: "" 
                }));
  
                yield put(setChatSelection({ 
                  chat_id: chatDetail.chat_id, 
                  chat_detail_id: chatDetail.id, 
                  isDisplay: true, 
                  target: targetList.image_sizes,
                  title: 'Tell me what is your image size',
                  options: targetOptionsList.image_sizes,
                  animationClass: 'slide-up'
                }));
              }
            }
            break;
          default:
            break;
        }
      }

    } else {
      // disable submit buttons due to insufficient credits
      yield put(setIsSufficientCredit(false));
      // show error reply
      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatDetail.chat_id, 
          replyData: {
            chat_detail_id: chatDetail.id,
            reply: `${i18n.t('errors.INSUFFICIENT_BALANCE')}`, 
            reply_role: 'system',
          },
          isSuccess: true,
        }
      });
      // scroll to bottom of chat
      yield put(setBottomRefTrigger(Date.now()));
    }
  } catch (error) {
    console.error('handleStorePrompt Saga Error:', error);
    yield put(setChatLoader({
      chat_id: chatId,
      isLoading: false, 
      isListening: false, 
      message: ""
    }));
  }
}

// handleStoreReply() function
function* handleStoreReply(action) {
  console.log('chatSagas: handleStoreReply', action);
  const { chatId, replyData, isSuccess } = action.payload;

  try {
    const chat_detail_id = replyData.chat_detail_id ?? '';
    const replyResponse = yield call(storeReplyApi, chatId, replyData);
    const { chatDetail, updateData, chat_memory } = replyResponse.data;

    if(chat_detail_id === ''){
      console.log('handleStoreReply: chatDetail', chatDetail);

      yield put(appendCurrentChatDetails(Array.isArray(chatDetail) ? chatDetail : [chatDetail]));
    } else {
      // Dispatch actions to update the state
      yield put(editCurrentChatDetails({
        chat_id: chatDetail.chat_id,
        chat_detail_id: chatDetail.id,
        updatedData: updateData,
      }));
    }

    yield put(editCurrentChatData({ field: 'last_active_datetime', value: updateData.updated_at }));
    yield put(setBottomRefTrigger(Date.now()));

    yield put(setChatLoader({ 
      chat_id: chatDetail.chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    })); 

    if(chat_memory) {
      yield put(appendChatMemory({
        chat_id: chatDetail.chat_id,
        chat_memory: Array.isArray(chat_memory) ? chat_memory : [chat_memory],
      }));
    }

    if(isSuccess){
      const stageList = yield select(selectChatStage, chatDetail.chat_id);
      const stageData = stageList[chatDetail.chat_id];
      console.log('handleStoreReply: stageData', stageData)
  
      switch (stageData.stage) {
        case '1':
          if(chat_detail_id !== '' && stageData.subStage === 'initial_prompt'){
            if(chatDetail.prompt_score > 0 && chatDetail.prompt_api_called === process.env.REACT_APP_MARVIS_CHATBOT){
              yield put(setChatLoader({ 
                chat_id: chatDetail.chat_id, 
                isLoading: false, 
                isListening: false, 
                message: "" 
              }));
              yield put(setChatSelection({ 
                chat_id: chatDetail.chat_id, 
                chat_detail_id: chatDetail.id, 
                isDisplay: true, 
                target: targetList.copywriting_strategy,
                title: 'Do you want me to provide a more strategize copywriting?',
                options: targetOptionsList.copywriting_strategy,
                animationClass: 'slide-up'
              }));
  
              yield put(editChatStage({
                chat_id: chatDetail.chat_id,
                updates: {
                  stage: '2',
                  subStage: 'strategy',
                  subStageOption: '',
                  chat_detail_id: chatDetail.id,
                  initial_prompt: chatDetail.edited_prompt,
                  initial_reply: chatDetail.edited_reply ?? '',
                }
              }));
            }
          } else if (stageData.subStage === 'image_to_copy_prompt'  
            || stageData.subStage === 'selected_copy_addon'){
  
            if(chat_detail_id !== ''){
              yield put(setChatLoader({ 
                chat_id: chatDetail.chat_id, 
                isLoading: false, 
                isListening: false, 
                message: "" 
              }));
              yield put(setChatSelection({ 
                chat_id: chatDetail.chat_id, 
                chat_detail_id: chatDetail.id, 
                isDisplay: true, 
                target: targetList.copywriting_strategy,
                title: 'Do you want me to provide a more strategize copywriting?',
                options: targetOptionsList.copywriting_strategy,
                animationClass: 'slide-up'
              }));
    
              yield put(editChatStage({
                chat_id: chatDetail.chat_id,
                updates: {
                  stage: '2',
                  subStage: 'strategy',
                  subStageOption: '',
                  chat_detail_id: chatDetail.id,
                  initial_prompt: chatDetail.edited_prompt,
                  initial_reply: chatDetail.edited_reply ?? '',
                }
              }));
            }
          }
          break;
        case '2':
          yield put(editChatStage({
            chat_id: chatDetail.chat_id,
            updates: {
              stage: '2',
              subStage: 'revise',
              subStageOption: '',
              initial_reply: '',
              initial_prompt: '',
              chat_detail_id: '',
            }
          }));
  
          if(chat_detail_id !== ''){ //for non system reply
            yield put(setChatLoader({ 
              chat_id: chatDetail.chat_id, 
              isLoading: false, 
              isListening: false, 
              message: "" 
            }));
            yield put(setChatSelection({ 
              chat_id: chatDetail.chat_id, 
              isDisplay: true, 
              chat_detail_id: chatDetail.id,
              target: targetList.revise_strategy_copywriting,
              title: 'Would you like me to expand on specific sections or revise this further? Let me know!',
              options: targetOptionsList.revise_strategy_copywriting,
              animationClass: 'slide-up'
            }));
          }
            
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.error('handleStoreReply Saga Error:', error);

    yield put(setChatLoader({ 
      chat_id: chatId, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));
  }
}


function* handleSelectionMode(action) {
  console.log('chatSagas: handleSelectionMode', action);

  const { chat_id, index } = action.payload;

  yield put(setChatLoader({ 
    chat_id: chat_id, 
    isLoading: true, 
    isListening: false, 
    message: `${i18n.t("Just a moment! We're getting everything ready for you. Thanks for hanging tight!")}` 
  }));

  const chatSelection = yield select(selectChatSelectionForCurrentChat);
  console.log('chatSelection', chatSelection);

  const chat_detail_id = chatSelection.chat_detail_id;
  const selectedItem = chatSelection.options[index];
  const target = chatSelection.target;
  const image_satisfaction = selectedItem.id;

  yield put(setChatSelection({ 
    chat_id: chat_id, 
    chat_detail_id: '', 
    isDisplay: false, 
    target: '',
    action: '',
    title: '',
    options: [],
    animationClass: 'slide-down'
  }));

  const stageList = yield select(selectChatStage, chat_id);
  const stageData = stageList[chat_id];

  //set prompt date and time
  const now = moment(); // Get current time
  const promptDate = now.format('DD/MM/YYYY'); // Exact date format: DD/MM/YYYY
  const promptTime = now.format('HH:mm:ss'); // Exact time format: HH:mm:ss (24-hour format)

  if (target === targetList.image_satisfaction) { // Title: Does this look like what you had in mind?
    // chatStages: ?
    yield call(handleUpdateImageSatisfaction, { payload: { chatDetailId: chatSelection.chat_detail_id, image_satisfaction: image_satisfaction } });

    switch (selectedItem.id) {
      case '1': //Yes
        const response = yield call(updateChatContentFlagApi, chat_detail_id, 'isImage');
        console.log('response', response);
        const { chatDetail } = response.data;

        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
            initial_prompt: '',
            chat_detail_id: '',
            image_size: '',
            image_style: '',
            selected_image_url: chatDetail.reply_url,
            selected_image_desc: chatDetail.edited_reply,
            status: stageData.selected_copy === '' ? 'ongoing' : 'complete',
          }
        }));

        if(stageData.selected_copy === '') {
          yield delay(100);
          yield put(setChatSelection({ 
            chat_id: chatDetail.chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.assist_copywriting,
            title: "I can assist you with the copywriting that fits best with your visuals. Would you like to proceed?",
            options: targetOptionsList.assist_copywriting,
            animationClass: 'slide-up'
          }));
        } else {
          yield call( handleStoreReply, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: 'Your copy and image is ready!', 
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
        }
        break;
      case '2': //regenerate image
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));
        break;
      case '3': //edit
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));
        break;
      case '4': //change background
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));
        break;
      case '5': //dont want image
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
          }
        }));
        //storePrompt
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: 'Ok sure, how can I assist you today?', 
              reply_role: 'system'
            },
            isSuccess: true,
          }
        });
        break;
      default:
        break;
    }
  } else if (target === targetList.copywriting_strategy) { // Title: Do you want me to provide a more strategize copywriting?
    // chatStages: 2(strategy)

    switch (selectedItem.id) {
      case 'custom_strategy':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            subStageOption: 'custom_strategy',
          }
        }));
        //store reply: Tell me your strategy.
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: 'Tell me your strategy', 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        //stage: 
        break;
      case 'no':
        //update isCopy flag for this 
        const response = yield call(updateChatContentFlagApi, chat_detail_id, 'isCopy');
        console.log('response', response);
        const { chatDetail } = response.data;

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
            initial_prompt: '',
            chat_detail_id: '',
            selected_copy: chatDetail.edited_reply,
            status: stageData.selected_image_url === '' ? 'ongoing' : 'complete',
          }
        }));

        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));

        if(stageData.selected_image_url === '') {
          yield delay(200);
          
          yield put(setChatSelection({ 
            chat_id: chatDetail.chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.assist_image,
            title: "I can assist you with the visuals that fits best with your copywriting. Would you like to proceed?",
            options: targetOptionsList.assist_image,
            animationClass: 'slide-up'
          }));
        } else {
          yield call( handleStoreReply, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: 'Your copy and image is ready!', 
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
        }
        break;
      default:
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            subStageOption: selectedItem.id,
          }
        }));
        yield call( handleStorePrompt, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            }
          }
        });
        break;
    }
  } else if (target === targetList.revise_strategy_copywriting) { // Title: Would you like me to expand on specific sections or revise this further? Let me know!
    // chatStages: 2(revise)
    switch (selectedItem.id) {
      case '1': //Yes
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: 'What do you want me to revise?', 
              edited_reply: 'What do you want me to revise from this copy?', 
              reply_role: 'assistant' 
            },
            isSuccess: true,
          }
        });
        break;
      case '2': //No
        const response = yield call(updateChatContentFlagApi, chat_detail_id, 'isCopy');
        console.log('response', response);
        const { chatDetail } = response.data;

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
            initial_prompt: '',
            chat_detail_id: '',
            initial_reply: '',
            selected_copy: chatDetail.edited_reply ?? '',
            status: stageData.selected_image_url === '' ? 'ongoing' : 'complete',
          }
        }));

        yield put(setChatLoader({ 
          chat_id: chatDetail.chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));

        if(stageData.selected_image_url === '') {
          yield delay(100);
          yield put(setChatSelection({ 
            chat_id: chatDetail.chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.assist_image,
            title: "I can assist you with the visuals that fits best with your copywriting. Would you like to proceed?",
            options: targetOptionsList.assist_image,
            animationClass: 'slide-up'
          }));
        } else {
          yield call( handleStoreReply, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: 'Your copy and image is ready!', 
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
        }
        break;
      default:
        break;
    }
  } else if (target === targetList.assist_copywriting) { // Title: I can assist you with the copywriting that fits best with your visuals. Would you like to proceed?
    // chatStages: 1(initial_prompt)
    switch (selectedItem.id) {
      case '1': //Yes
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'image_to_copy_prompt',
          }
        }));

        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: 'Tell me more about your product.', 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      case '2': //No
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
          }
        }));

        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: 'What else can i help you with?', 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      default:
        break;
    }
  } else if (target === targetList.assist_image) { // Title: I can assist you with the visuals that fits best with your copywriting. Would you like to proceed?
    // chatStages: 1(initial_prompt)
    switch (selectedItem.id) {
      case '1': //Yes
        yield delay(100);
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '3',
            subStage: 'initial_image',
            status: 'ongoing',
          }
        }));
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));
        yield put(setChatSelection({ 
          chat_id: chat_id, 
          chat_detail_id: chat_detail_id, 
          isDisplay: true, 
          target: targetList.image_sizes,
          title: 'Tell me what is your image size',
          options: targetOptionsList.image_sizes,
          animationClass: 'slide-up'
        }));
        break;
      case '2': //No
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: 'No problem, how can I assist you today?', 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      default:
        break;
    }
  } else if(target === targetList.image_sizes) { // Title: Tell me what is your image size
    // chatStages: 3(initial_image)
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        image_size: selectedItem.id,
      }
    }));
    yield delay(100);
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));

    if(stageData.image_style === ''){
      yield put(setChatSelection({ 
        chat_id: chat_id, 
        chat_detail_id: chat_detail_id, 
        isDisplay: true, 
        target: targetList.image_styles,
        title: 'Tell me what kind of style you want your image to be like?',
        options: targetOptionsList.image_styles,
        animationClass: 'slide-up'
      }));
    } else {
      yield call( handleStorePrompt, { 
        payload: { 
          chatId: chat_id, 
          promptData: {
            prompt: '',
            promptDate: promptDate,
            promptTime: promptTime
          }
        }
      });
    }
    
  } else if(target === targetList.image_styles) { // Title: Tell me what kind of style you want your image to be like?
    // chatStages: 3(initial_image - ongoing)
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        image_style: selectedItem.id ?? '',
      }
    }));

    yield call( handleStorePrompt, { 
      payload: { 
        chatId: chat_id, 
        promptData: {
          prompt: '',
          promptDate: promptDate,
          promptTime: promptTime
        }
      }
    });
    
  } else if (target === targetList.generate_image) { // Title: Do you want to generate image based on the above content?
    // chatStages: 3(initial_image)
    switch(selectedItem.id) {
      case '1': //Yes
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            status: 'ongoing'
          }
        }));
        break;
      case '2': //No
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
            status: 'ongoing'
          }
        }));
        break;
      default:
        break;
    }
    yield call( handleStorePrompt, { 
      payload: { 
        chatId: chat_id, 
        promptData: {
          prompt: '',
          promptDate: promptDate,
          promptTime: promptTime
        }
      }
    });
  } else if (target === targetList.revise_complete_ads) { // Title: It seems like you are starting a new advertisement for another product, as your digital marketing assistant. I would advise you to 'start a new chat' for each product. Or do you want to revise anything from our discussion so far?
    // chatStages: 1(initial_prompt), 3(initial_image)

    switch(selectedItem.id){
      case 'revise_copy':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'selected_copy_addon',
            status: 'revise',
          }
        }));
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: 'Tell me what do you wanna add to the copywriting.', 
              reply_role: 'system',
            },
            isSuccess: true,
          }
        });
        break;
      case 'revise_image':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '3',
            subStage: 'selected_image_revise',
            status: 'revise',
          }
        }));
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: 'Tell me what do you wanna add to the image.', 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      default:
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: 'Ok sure, please start a new chat to continue with a new advertisement.', 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
    }
  }
}

function* handleUploadImage(action) {
  console.log('chatSagas: handleUploadImage', action);

  const { chatId, promptData } = action.payload;

  try {
    let updatedPromptData = {
      ...promptData,
      prompt_api_called: `${process.env.MARVIS_IMAGE_FLUX}`,
      edited_prompt: promptData.prompt,
    };

    // store reply
    console.log('handleStorePrompt: updatedPromptData', updatedPromptData);
    const uploadImageResponse = yield call(uploadImageApi, chatId, updatedPromptData);
    console.log('uploadImageResponse', uploadImageResponse);

    const { chatDetail, chat_memory, sufficient_credit } = uploadImageResponse.data;

    yield put(appendCurrentChatDetails(Array.isArray(chatDetail) ? chatDetail : [chatDetail]));
    yield put(setBottomRefTrigger(Date.now()));
    
    yield put(editChatStage({
      chat_id: chatId,
      updates: {
        stage: '1',
        initial_prompt: updatedPromptData.edited_prompt,
      }
    }));

    yield put(editCurrentChatData({ field: 'last_active_datetime', value: chatDetail.updated_at }));
    
    if(chat_memory) {
      yield put(appendChatMemory({
        chat_id: chatDetail.chat_id,
        chat_memory: Array.isArray(chat_memory) ? chat_memory : [chat_memory],
      }));
    }

    yield delay(100); // Optional delay

    if(sufficient_credit) {  
      yield put(setChatLoader({ 
        chat_id: chatDetail.chat_id, 
        isLoading: true, 
        isListening: false, 
        message: `${i18n.t("Hang tight! Your image is on its way!")}`
      }));
      yield call(handleTextToImage, chatDetail);
    } else {
      yield put(setIsSufficientCredit(false));
      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatDetail.chat_id, 
          replyData: {
            chat_detail_id: chatDetail.id,
            reply: `${i18n.t('errors.FAILED_AI_RESPONSE')}`, 
            reply_role: 'system' 
          },
          isSuccess: false
        }
      });
      yield put(setBottomRefTrigger(Date.now()));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      console.log("promptApi Server Error: ", error.response.data);
      // setServerErrors(error.response.data);
    } else {
      console.error('promptApi Error:', error);
    }
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatId, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t('errors.INSUFFICIENT_BALANCE')}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chatId, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));

  }
}

function* handleCamera(action) {
  console.log('chatSagas: handleCamera', action);

  const { chatId, promptData } = action.payload;

  try {
    yield put(setChatLoader({ 
      chat_id: chatId, 
      isLoading: true, 
      isListening: false, 
      message: `${i18n.t("Hang tight! Your image is on its way!")}`
    }));

    let updatedPromptData = {
      ...promptData,
      prompt_api_called: `${process.env.MARVIS_IMAGE_FLUX}`,
    };

    // store reply
    console.log('handleStorePrompt: updatedPromptData', updatedPromptData);
    const uploadImageResponse = yield call(uploadImageApi, chatId, updatedPromptData);
    console.log('uploadImageResponse', uploadImageResponse);

    const { chatDetail, sufficient_credit } = uploadImageResponse.data;

    yield put(appendCurrentChatDetails(Array.isArray(chatDetail) ? chatDetail : [chatDetail]));
    yield put(setBottomRefTrigger(Date.now()));
    
    //call object recognition
    let image_url = chatDetail.prompt_url ? getFullImageUrl(chatDetail.prompt_url) : '';
    if(backend_url === "http://127.0.0.1:8000") {
      image_url = 'https://images.squarespace-cdn.com/content/v1/616cfb6a0343cf58c0a570b5/c5505422-d6f5-4817-ad8d-a80bf328af06/IMG_1463.PNG';//'https://www.coca-cola.com/content/dam/onexp/us/en/homepage/usa_home_1_campaign_card_1280x1024.jpg/width1960.jpg';
    }
    const recognitionResponse = yield call(aiImageRecognitionApi, image_url);
    console.log('recognitionResponse', recognitionResponse);

    let edited_prompt = recognitionResponse.data + ' ' + updatedPromptData.prompt;
    yield put(editChatStage({
      chat_id: chatId,
      updates: {
        stage: '1',
        initial_prompt: edited_prompt,
      }
    }));

    let data = {
      edited_prompt: edited_prompt,
    };
    const updateResponse = yield call(updateChatDetailApi, chatDetail.id, data);
    const { updateData, chat_memory } = updateResponse.data;
    console.log('updateResponse', updateResponse);

    yield put(editCurrentChatDetails({
      chat_id: chatDetail.chat_id,
      chat_detail_id: chatDetail.id,
      updatedData: updateData,
    }));

    yield put(editCurrentChatData({ field: 'last_active_datetime', value: updateData.updated_at }));
    
    if(chat_memory) {
      yield put(appendChatMemory({
        chat_id: chatDetail.chat_id,
        chat_memory: Array.isArray(chat_memory) ? chat_memory : [chat_memory],
      }));
    }

    yield delay(100); // Optional delay

    if(sufficient_credit) {  
      yield put(setChatLoader({ 
        chat_id: chatDetail.chat_id, 
        isLoading: true, 
        isListening: false, 
        message: `${i18n.t("Hang tight! Your image is on its way!")}`
      }));
      yield call(handleTextToImage, chatDetail);
    } else {
      yield put(setIsSufficientCredit(false));
      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatDetail.chat_id, 
          replyData: {
            chat_detail_id: chatDetail.id,
            reply: `${i18n.t('errors.INSUFFICIENT_BALANCE')}`, 
            reply_role: 'system' 
          },
          isSuccess: false,
        }
      });
      yield put(setBottomRefTrigger(Date.now()));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      console.log("promptApi Server Error: ", error.response.data);
      // setServerErrors(error.response.data);
    } else {
      console.error('promptApi Error:', error);
    }
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatId, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t('errors.FAILED_AI_RESPONSE')}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chatId, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));

  }
}

// Handle Backend API end------------------------------------------- //
// Handle Marvis API ----------------------------------------------- //
function* handleChatbot(chatDetail, newDetail = false) {
  console.log('chatSagas: handleChatbot');

  try {
    const chatMemory = yield select(selectChatMemoryForCurrentChat);
    console.log('handleChatbot: chatMemory',chatMemory);

    const aiResponse = yield call(aiChatbotApi, chatMemory);
    console.log('handleChatbot: aiResponse', aiResponse);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: newDetail ? '' : chatDetail.id,
          reply: aiResponse.data ?? '', 
          edited_reply: aiResponse.data ?? '', 
          reply_role: 'assistant' 
        },
        isSuccess: true,
      }
    });

  } catch (error) {
    console.error('handleChatbot Saga Error:', error);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: chatDetail.id,
          reply: `${i18n.t('errors.FAILED_AI_RESPONSE')}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleTextToImage(chatDetail) {
  console.log('chatSagas: handleTextToImage: chatDetail', chatDetail)

  try {
    // call api: aiImageFlux
    const stageList = yield select(selectChatStage, chatDetail.chat_id);
    const stageData = stageList[chatDetail.chat_id];
    console.log('handleTextToImage: stageData', stageData)

    let prompt = chatDetail.edited_prompt;
    const apiParameter = {
      size: stageData.image_size
    }
    // if(chatDetail.edited_prompt !== '') {
    //   prompt = stageData.initial_prompt;
    // }
    // switch (stageData.subStage) {
    //   case 'initial_image':
    //       //
    //     break;
    //   case 'edit_image':
    //     break;
    //   default:
    //     break;
    // }
    let image_url = chatDetail.prompt_url ? getFullImageUrl(chatDetail.prompt_url) : '';
    if(backend_url === "http://127.0.0.1:8000") {
      image_url = 'https://images.nightcafe.studio/jobs/dDobcxsvhPdi8nsYyxKR/dDobcxsvhPdi8nsYyxKR--1--a6f3y.jpg';//'https://www.coca-cola.com/content/dam/onexp/us/en/homepage/usa_home_1_campaign_card_1280x1024.jpg/width1960.jpg';
    }
    console.log('handleTextToImage: prompt',prompt);


    const aiResponse = yield call(aiImageFluxApi, prompt, image_url, apiParameter);
    console.log('handleTextToImage: aiResponse',aiResponse);

    const aiDataResult = aiResponse.data;

    const reply_url = aiDataResult.images[0].url ?? '';
    let edited_reply = '';

    if(reply_url !== ''){
      //recognize image
      const recognitionResponse = yield call(aiImageRecognitionApi, reply_url);
      console.log('recognitionResponse', recognitionResponse);
      
      edited_reply = recognitionResponse.data;
    }
    
    // call function: apiUpdateChatReply
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: chatDetail.id,
          reply_url: reply_url, 
          edited_reply: edited_reply,
          reply_role: 'assistant' ,
        },
        isSuccess: true,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chatDetail.chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));
    yield put(setChatSelection({ 
      chat_id: chatDetail.chat_id, 
      chat_detail_id: chatDetail.id, 
      isDisplay: true, 
      target: targetList.image_satisfaction,
      title: 'Does this look like what you had in mind?',
      options: targetOptionsList.image_satisfaction,
      animationClass: 'slide-up'
    }));

    if(stageData.subStage === 'selected_image_revise'){
      yield put(editChatStage({
        chat_id: chatDetail.chat_id,
        updates: {
          stage: '3',
          subStage: 'selected_image_edit',
        }
      }));
    } else {
      yield put(editChatStage({
        chat_id: chatDetail.chat_id,
        updates: {
          stage: '3',
          subStage: 'edit_image',
        }
      }));
    }
    
  } catch (error) {
    console.error('handleTextToImage Saga Error:', error);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: chatDetail.id,
          reply: `${i18n.t('errors.FAILED_AI_RESPONSE')}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleChangeBackground(chatDetail) {
  console.log('chatSagas: handleChangeBackground');

  try {
    
  } catch (error) {
    console.error('handleChangeBackground Saga Error:', error);
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: chatDetail.id,
          reply: `${i18n.t('errors.FAILED_AI_RESPONSE')}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleRemoveBackground(chatDetail) {
  console.log('chatSagas: handleRemoveBackground');

  try {
    
  } catch (error) {
    console.error('handleRemoveBackground Saga Error:', error);
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: chatDetail.id,
          reply: `${i18n.t('errors.FAILED_AI_RESPONSE')}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleMasking(chatDetail) {
  console.log('chatSagas: handleMasking');

  try {
    
  } catch (error) {
    console.error('handleMasking Saga Error:', error);
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: chatDetail.id,
          reply: `${i18n.t('errors.FAILED_AI_RESPONSE')}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleInpainting(chatDetail) {
  console.log('chatSagas: handleInpainting');

  try {
    
  } catch (error) {
    console.error('handleInpainting Saga Error:', error);
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: chatDetail.id,
          reply: `${i18n.t('errors.FAILED_AI_RESPONSE')}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}
// Handle Marvis API end-------------------------------------------- //

// Root Saga for Chat
export function* chatSagas() {
  yield takeLatest(chatClickAction.type, handleChatClick);
  yield takeLatest(fetchChatMemoryAction.type, handleFetchChatMemory);
  yield takeLatest(updateReactionAction.type, handleUpdateReaction);
  yield takeLatest(storePromptAction.type, handleStorePrompt);
  yield takeLatest(fetchMoreChatDetailsAction.type, handleFetchMoreChatDetails);
  yield takeLatest(createChatAction.type, handleCreateChat);
  yield takeLatest(selectionModeAction.type, handleSelectionMode);
  yield takeLatest(uploadImageAction.type, handleUploadImage);
  yield takeLatest(cameraAction.type, handleCamera);

}

export default chatSagas;


