//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : useAxiosInterceptors.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (12 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240712     Jecyline     create useAxiosInterceptors
//
//***************************************************************************/
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setupInterceptors } from '../services/api';

const useAxiosInterceptors = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setupInterceptors(navigate);
  }, [navigate]);
};

export default useAxiosInterceptors;
