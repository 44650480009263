//****************************************************************************
//* Filename  : chatContants.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (06 Sept 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240906     Jecyline     Create chatContants.jsx
//* 20240911     Jecyline     restructure constant to targetList and targetOptionsList for new brain flow
//
//***************************************************************************/

export const targetList = {
  image_satisfaction: "Image Satisfaction",
  copywriting_strategy: "Copywriting Strategy",
  revise_complete_ads: "Revise Complete Ads",
  image_sizes: "Image Size",
  image_styles: "Image Style",
  edit_image: "Edit Image",
  assist_copywriting: "Assist Copywriting",
  assist_image: "Assist Image",
  revise_strategy_copywriting: "Revise Strategy Copywriting",
  generate_image: "Generate Image",
};

export const targetOptionsList = {
  image_satisfaction: [
    { id: '1', value: 'Yes' },
    { id: '2', value: 'No, I want to create a new image.' },
    { id: '3', value: 'No, i would like to regenerate the image.' },
    { id: '4', value: 'No, I would like to edit this image.' },
    { id: '5', value: 'No, I do not want an image' },
  ],
  copywriting_strategy: [
    { id: 'driving_conversion', value: 'Driving conversions', description: "Advertise with direct conversion focused approach by focusing on your product and also the problem." },
    { id: 'consultancy', value: 'Consultancy', description: "Offering free consultations encourages business owners to explore customized UI/UX solutions, boosting lead generation." },
    { id: 'time limited offer', value: 'Time limited offer', description: "Creating urgency with a limited-time offer prompts business owners to quickly secure premium UI/UX design services, driving immediate leads." },
    { id: 'discounts', value: 'Discounts', description: "Attracts business owners by offering affordable, high-quality UI/UX design, boosting lead conversions." },
    { id: 'custom_strategy', value: 'Custom strategy', description: "" },
    { id: 'no', value: 'No, I’m happy with the current copywriting', description: "" },
  ],
  revise_complete_ads: [
    {id: 'revise_copy', value: 'I would like to revise my copywriting.'},
    {id: 'revise_image', value: 'I would like to revise my image'},
  ],
  image_sizes: [
    {id: 'square_hd', value: 'Square image (Recommended)', description: "1:1 aspect ratio, perfect for square social media posts."},
    {id: 'landscape_16_9', value: 'Landscape image', description: "16:9 ratio, perfect for landscape content on various platforms."},
    {id: 'portrait_16_9', value: 'Story image', description: "9:16 ratio, ideal for stories, reels, and vertical content."},
    {id: 'portrait_4_3', value: 'Portrait image', description: "3:4 ratio, great for social media feeds that prefer vertical space."},
  ],
  image_styles: [
    {id: 'realistic', value: 'Realistic (Recommended)', description: "A realistic image looks like a real-life photo, with accurate details and colors."},
    {id: 'film noir', value: 'Film Noir', description: "A film noir image has a dark, dramatic look, usually in black and white."},
    {id: 'cartoon', value: 'Cartoon', description: "A cartoon image is a simplified, colorful drawing with exaggerated features."},
    {id: 'comic', value: 'Comic', description: "A comic image is a bold, colorful drawing with strong lines, often used in storytelling."},
    {id: 'painting', value: 'Painting', description: "A painting image is created with brushstrokes, showing artistic textures and colors."},
    {id: 'sketch', value: 'Sketch', description: "A sketch image is a quick, rough drawing with simple lines and minimal detail."},
    {id: 'pixel art', value: 'Pixel Art', description: "Pixel art is a digital image made up of small, square pixels, creating a retro, blocky look."},
    {id: 'studio shot', value: 'Studio Shot', description: "A studio shot is a professionally lit and posed photo taken in a controlled environment."},
    {id: 'surreal', value: 'Surreal', description: "A surreal image blends reality and fantasy, creating dreamlike, unusual scenes."},
  ],
  edit_image: [
    {id: '1', value: 'No, I want to create a new image.', description: "1:1 aspect ratio, perfect for square social media posts."},
    {id: '2', value: 'Landscape No, i would like to regenerate the image', description: "16:9 ratio, perfect for landscape content on various platforms."},
    {id: '3', value: 'Story image', description: "9:16 ratio, ideal for stories, reels, and vertical content."},
    {id: '4', value: 'Portrait image', description: "3:4 ratio, great for social media feeds that prefer vertical space."},
  ],
  assist_copywriting: [
    { id: '1', value: 'Yes' },
    { id: '2', value: 'No' },
  ],
  assist_image: [
    { id: '1', value: 'Yes' },
    { id: '2', value: 'No' },
  ],
  revise_strategy_copywriting: [
    { id: '1', value: 'Yes' },
    { id: '2', value: 'No, I’m happy with this copywriting' },
  ],
  generate_image: [
    { id: '1', value: 'Yes' },
    { id: '2', value: 'No' },
  ],
};

export const editModeOptions = [
  { id: '1', value: 'Generate New Image' }, // txtToImage
  { id: '2', value: 'Regenerate Image' }, // objReg -> txtToImage
  { id: '3', value: 'Image Edit' }, // canva
  { id: '4', value: 'Remove Background' },
  { id: '5', value: 'Change Background' },
  { id: '6', value: 'Change Subject' }, // mask -> inpainting
  { id: '7', value: 'Generate Copywriting' }, // chatbot
];

// export const copywritingStyleOptions = [
//   { id: '1', value: 'AIDA', description: 'Attention, Interest, Desire, Action' },
//   { id: '2', value: 'PAS', description: 'Problem, Agitate, Solution' },
//   { id: '3', value: '4Cs', description: 'Clear, Concise, Compelling, Credible' },
//   { id: '4', value: 'FAB', description: 'Features, Advantages, Benefits' },
//   { id: '5', value: 'ACC', description: 'Awareness, Comprehension, Conversion' },
//   { id: '6', value: 'SLAP', description: 'Stop, Look, Act, Purchase' },
// ];

