//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : endpoints.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (29 Aug 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240829     Jecyline     Create endpoints.jsx
//* 20240829     Jecyline     add CHAT_FETCH_CHAT_MEMORY, MARVIS_TRANSLATOR & MARVIS_PROOFREADER
//* 20240905     Jecyline     add CHAT, CHAT_GET_DETAILS, CHAT_GET_NEXT_API, CHAT_STORE_REPLY, CHAT_UPDATE_IMAGE_SATISFACTION, MARVIS_RECOGNITION
//* 20240911     Jecyline     add CHAT_UPDATE_CONTENT_FLAG
//
//***************************************************************************/
const API_ENDPOINTS = {
    // AUTH_LOGIN: '/auth/login',

    //Chat
    CHAT: () => `/chat`,
    CHAT_GET_DETAILS: (chatId, page) => `/chat-detail/${chatId}?page=${page}`,
    CHAT_GET_NEXT_API: () => `/chat-detail/get-next-api`,
    CHAT_FETCH_CHAT_MEMORY: (chatId) => `/chat-detail/all-memory/${chatId}`,
    CHAT_STORE_PROMPT: (chatId) => `/chat-detail/prompt/${chatId}`,
    CHAT_STORE_REPLY:  (chatId) => `/chat-detail/reply/${chatId}`,
    CHAT_UPDATE_DETAIL: (chatDetailId) => `/chat-detail/update/${chatDetailId}`,
    CHAT_UPDATE_REACTION: (chatDetailId) => `/chat-detail/update-reaction/${chatDetailId}`,
    CHAT_UPDATE_IMAGE_SATISFACTION: (chatDetailId) => `/chat-detail/update-image-satisfaction/${chatDetailId}`,
    CHAT_UPLOAD_IMAGE: (chatId) => `/chat-detail/upload-image/${chatId}`,
    CHAT_UPDATE_CONTENT_FLAG: (chatDetailId) => `/chat-detail/update-content-flag/${chatDetailId}`,

    //Marvis
    MARVIS_AI_CHATBOT: () => `${process.env.REACT_APP_MARVIS_AI}${process.env.REACT_APP_MARVIS_CHATBOT}`,
    MARVIS_IMAGE_LEONARDO: () => `${process.env.REACT_APP_MARVIS_AI}${process.env.REACT_APP_MARVIS_IMAGE_LEONARDO}`,
    MARVIS_IMAGE_DALLE: () => `${process.env.REACT_APP_MARVIS_AI}${process.env.REACT_APP_MARVIS_IMAGE_DALLE}`,
    MARVIS_IMAGE_FLUX: () => `${process.env.REACT_APP_MARVIS_AI}${process.env.REACT_APP_MARVIS_IMAGE_FLUX}`,
    MARVIS_IMAGE_INPAINTING: () => `${process.env.REACT_APP_MARVIS_AI}${process.env.REACT_APP_MARVIS_IMAGE_INPAINTING}`,
    MARVIS_IMAGE_MASK: () => `${process.env.REACT_APP_MARVIS_AI}${process.env.REACT_APP_MARVIS_IMAGE_MASK}`,
    MARVIS_BACKGROUND_GENERATE: () => `${process.env.REACT_APP_MARVIS_AI}${process.env.REACT_APP_MARVIS_BACKGROUND_GENERATE}`,
    MARVIS_BACKGROUND_REMOVE: () => `${process.env.REACT_APP_MARVIS_AI}${process.env.REACT_APP_MARVIS_BACKGROUND_REMOVE}`,
    MARVIS_RECOGNITION: () => `${process.env.REACT_APP_MARVIS_AI}${process.env.REACT_APP_MARVIS_RECOGNITION}`,
    MARVIS_TRANSLATOR: () => `${process.env.REACT_APP_MARVIS_AI}${process.env.REACT_APP_MARVIS_TRANSLATOR}`,
    MARVIS_PROOFREADER: () => `${process.env.REACT_APP_MARVIS_AI}${process.env.REACT_APP_MARVIS_PROOFREADER}`,

  };
  
  export default API_ENDPOINTS;
  