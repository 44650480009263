//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : scrollChatUtils.js
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (24 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240724     Jecyline     create scrollChatUtils.jsx
//* 20240801     Jecyline     add jumpToDiv
//***************************************************************************/
export const scrollToDiv = (ref) => {
  if (ref?.current) {
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }, 100);

  }
};

export const jumpToDiv = (ref) => {
  if (ref?.current) {
    setTimeout(() => {
      ref.current.scrollIntoView();
    }, 100);
  }
};