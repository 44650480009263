//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : Register.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      create Register.jsx
//* 20240705     Jecyline     add validation, change handleSubmit() to handleSubmit(onSubmit), import FacebookSignUpButton & GoogleSignUpButton
//* 20240715     Jecyline     get referralCode From url param and pass to backend
//* 20240718     Jecyline     language selection module
//* 20240801     Glynnis      add staging link for terms
//* 20240802     Jecyline     change /marvis/terms to /terms
//* 20240814     Jecyline     remove password_confirmation parameter when call /register api
//* 20240902     Glynnis      revamp UI
//
//***************************************************************************/
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
// axios
import axios from 'axios';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Applogo } from "../../../Routes/ImagePath";
import { emailrgx, passwordrgx } from "../../../utils/RegEx";
import { api_url } from "../../../base_urls";
import FacebookSignUpButton from "../../../components/FacebookSignUpButton";
import GoogleSignUpButton from "../../../components/GoogleSignUpButton";
import LanguageSelector from '../../layout/LanguageSelector';
import { useTranslation } from 'react-i18next';

const Register = (props) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(`${t('Invalid email address')}`)
      .matches(emailrgx, `${t('Invalid email address')}`)
      .required(`${t('Email')}${t(' is required')}`)
      .trim(),
    name: yup
      .string()
      .required(`${t('Name')}${t(' is required')}`)
      .trim(),
    password: yup
      .string()
      .matches(passwordrgx, {
        message: `${t('Password')} ${t(' must be 8-20 characters long, include at least one uppercase letter, one digit, and one special character')}`,
        excludeEmptyString: true
      })
      .required(`${t('Password')}${t(' is required')}`)
      .trim(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], `${t('Password must match')}`)
      .required(`${t('Please confirm your password')}`),
    isTermsChecked: yup
      .bool()
      .oneOf([true], `${t('You must agree to the terms and conditions')}`)
      .required(`${t('You must agree to the terms and conditions')}`)
  });
  
  const [checkUser, setCheckUser] = useState(false); // State for check existence email field
  const [passwordEye, setPasswordEye] = useState(true); // State for password field
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(true); // State for confirm password field
  const [serverErrors, setServerErrors] = useState({});

  const { referralCode } = useParams();

  const { 
    control, 
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });
  const navigate = useNavigate();
  
  const onSubmit = async (data) => {

    try {
        const response = await fetch(api_url + '/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: data.name, email: data.email, password: data.password, referralCode })
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.log('Validation errors:', errorData);
            // Handle the validation errors here
            // For example, set the errors state to show messages
            setServerErrors(errorData);
    
            throw new Error(errorData.error || 'Failed to register user');
        } else {
          const responseData = await response.json();
          console.log(responseData); // Handle response data as needed

          navigate('/otp', { state: { email: data.email } });
        }

    } catch (error) {
        console.error('Error:', error);
    }
  }

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="account-content">
          <div className="container">
            {/* Account Logo */}
            <div className="account-logo">
              <Link to="/dashboard">
                <img src={Applogo} alt="Omninexus Customer Retention Platform" />
              </Link>
            </div>
            {/* /Account Logo */}
            <div className="account-box">
              <div className="account-wrapper">
                <LanguageSelector />
                
                {/* Account Form */}
                <div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Email')}</label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`form-control`}
                            type="email"
                            {...field}
                            autoComplete="false"
                          />
                        )}
                        defaultValue=""
                      />
                      <span className="text-danger d-block">
                        {checkUser ? "This Email is Already exist" : ""}
                      </span>
                      <span className="text-danger d-block">
                        {errors.email && <span>{errors.email.message}</span>}
                        {serverErrors.email && <p>{t(serverErrors.email[0])}</p>}
                      </span>
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Name')}</label>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`form-control`}
                            type="text"
                            {...field}
                            autoComplete="false"
                          />
                        )}
                        defaultValue=""
                      />
                      <span className="text-danger d-block">
                        {errors.name && <span>{errors.name.message}</span>}
                        {serverErrors.name && <p>{serverErrors.name[0]}</p>}
                      </span>
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Password')}</label>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <div
                            className="pass-group"
                            style={{ position: "relative" }}
                          >
                            <input
                              className={`form-control`}
                              type={passwordEye ? "password" : "text"}
                              {...field}
                              autoComplete="false"
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "30%",
                              }}
                              onClick={() => setPasswordEye(!passwordEye)}
                              className={`fa toggle-password ${
                                passwordEye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </div>
                        )}
                        defaultValue=""
                      />
                      <span className="text-danger d-block">
                        {errors.password && <span>{errors.password.message}</span>}
                        {serverErrors.password && <p>{serverErrors.password[0]}</p>}
                      </span>
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Confirm Password')}</label>
                      <Controller
                        name="confirmPassword"
                        control={control}
                        render={({ field }) => (
                          <div
                            className="pass-group"
                            style={{ position: "relative" }}
                          >
                            <input
                              className={`form-control`}
                              type={confirmPasswordEye ? "password" : "text"}
                              {...field}
                              autoComplete="false"
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "30%",
                              }}
                              onClick={() =>
                                setConfirmPasswordEye(!confirmPasswordEye)
                              }
                              className={`fa toggle-password ${
                                confirmPasswordEye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </div>
                        )}
                        defaultValue=""
                      />
                      <span className="text-danger d-block">
                        {errors.confirmPassword && <span>{errors.confirmPassword.message}</span>}
                      </span>
                    </div>
                    <div className="input-block">
                      <Controller
                        name="isTermsChecked"
                        control={control}
                        render={({ field: { value, onChange, onBlur, name } }) => (
                          <input
                            type="checkbox"
                            name={name}
                            checked={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            style={{ marginRight: '18px' }}
                          />
                        )}
                        defaultValue={false}
                      />
                      <label htmlFor="terms">
                        {t('I agree to the')} <a href="/terms" target="_blank" rel="noopener noreferrer">{t('Terms and Conditions')}</a>
                      </label>
                      <span className="text-danger d-block">
                        {errors.isTermsChecked && <span>{errors.isTermsChecked.message}</span>}
                      </span>
                    </div>
                    <div className="input-block text-center">
                      <button type="submit" className="btn btn-primary account-btn">{t('Register')}</button>
                    </div>
                  </form>
                  <div className="input-block" style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <div className="horizontal-line"/>
                    <span style={{ color: '#9A9797', padding: '0 5px' }}>{t('OR')}</span>
                    <div className="horizontal-line"/>
                  </div>
                  {/* <div className="input-block text-center">
                    <button
                      className="btn account-icon-button"
                      type="button"
                    >
                      <i className="fab fa-apple account-icon"></i>
                      <div className="account-text">Sign in with Apple</div>
                    </button>
                  </div> */}
                  <div className="input-block text-center">
                    <FacebookSignUpButton />
                  </div>
                  <div className="input-block text-center">
                    <GoogleSignUpButton />
                  </div>
                  <div className="account-footer">
                    <p>
                      {t('Already have an account')}? <Link to="/">{t('Login')}</Link>
                    </p>
                  </div>
                </div>
                {/* /Account Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
