//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : NewChatModal.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (24 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240724     Jecyline     create NewChatModal.jsx for add new chat room
//* 20240726     Jecyline     language translation
//
//***************************************************************************/
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const NewChatModal = ({ show, onClose, onCreate }) => {
  const { t } = useTranslation();

  const [newChatName, setNewChatName] = useState('');

  const handleCreate = () => {
    onCreate(newChatName);
    setNewChatName('');
  };

  return (
    <div>
      {/* Backdrop */}
      <div className={`modal-backdrop ${show ? 'show' : ''}`} onClick={onClose}></div>

      {/* Modal */}
      <div className={`modal custom-modal ${show ? 'show d-block' : 'd-none'} fade`} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t('New Chat Room')}</h5>
              <button type="button" className="btn-close" onClick={onClose}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                type="text"
                value={newChatName}
                onChange={(e) => setNewChatName(e.target.value)}
                placeholder={t('Enter chat room name')}
              />
            </div>
            <div className="submit-section mt-0 mb-3">
              <button type="button" className="btn btn-primary" onClick={handleCreate}>
                {t('Create')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewChatModal;
